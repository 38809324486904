import { IControl } from "maplibre-gl";
import { setUnsavedChanges } from "./utils";

const DISABLED_CLASS = "mapboxgl-ctrl-disabled";

export default class ExportChangesControl implements IControl {
    map: maplibregl.Map;
    mapMinZoom: number;
    container: HTMLDivElement;
    submitBtn: HTMLButtonElement;
    saveBtn: HTMLButtonElement;
    loadBtn: HTMLButtonElement;

    createBtn(innerHTML: string, tooltip: string, onClick: () => {}): HTMLButtonElement {
        const btn = document.createElement("button");
        btn.innerHTML = innerHTML;
        btn.type = "button";
        // @ts-ignore
        btn.ariaLabel = tooltip;
        btn.title = tooltip;
        btn.classList.add("tooltip", "tooltip-left");
        btn.dataset.tooltip = tooltip;
        btn.onclick = onClick;
        return btn;
    }

    setEnabled(enabled: boolean, externalUser: boolean) {
        if (!enabled) {
            this.submitBtn.classList.add(DISABLED_CLASS);
            this.saveBtn.classList.add(DISABLED_CLASS);
        } else {
            if (!externalUser) {
                this.submitBtn.classList.remove(DISABLED_CLASS);
            }
            this.saveBtn.classList.remove(DISABLED_CLASS);
        }
        setUnsavedChanges(enabled);
        if (!externalUser || !enabled) {
            this.submitBtn.disabled = !enabled;
        }
        this.saveBtn.disabled = !enabled;
    }

    onAdd(map: maplibregl.Map) {
        this.map = map;

        this.submitBtn = this.createBtn(
            `
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M20,6A2,2 0 0,1 22,8V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4H10L12,6H20M10.75,13H14V17H16V13H19.25L15,8.75" />
                </svg>
            `,
            "Submit to Asset DB",
            () => this.map.fire("export-changes"),
        );
        this.submitBtn.disabled = true;
        this.submitBtn.classList.add(DISABLED_CLASS);
        this.saveBtn = this.createBtn(
            `
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
                </svg>
            `,
            "Save to GeoJSON",
            () => this.map.fire("save-changes"),
        );
        this.saveBtn.disabled = true;
        this.saveBtn.classList.add(DISABLED_CLASS);
        this.loadBtn = this.createBtn(
            `
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10L12,6H19A2,2 0 0,1 21,8H21L4,8V18L6.14,10H23.21L20.93,18.5C20.7,19.37 19.92,20 19,20Z" />
                </svg>
            `,
            "Load GeoJSON",
            () => this.map.fire("load-features"),
        );
        this.container = document.createElement("div");
        this.container.id = "export-changes-control";
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
        this.container.appendChild(this.submitBtn);
        this.container.appendChild(this.saveBtn);
        this.container.appendChild(this.loadBtn);
        return this.container;
    }

    onRemove() {
        this.container.parentNode?.removeChild(this.container);
    }
}
