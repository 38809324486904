import { CreateElement } from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import {
    VBtn,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VDivider,
    VSpacer,
    VTextField,
    VFileInput,
    VProgressCircular,
} from "vuetify/lib";

import { GeoJSONPoint, GeoJSONPosition, stringify } from "wellknown";
import { getAuthToken, gigamapApiFactory } from "@/services";
import { getUserData } from "@/oauth";
import { getAuthVersion } from "@/auth-helpers";
import { projectToOSGB36, accessTokenFromStore, showSnackbar } from "@/utils";

interface GigastoreFileUploadProps {
    "v-model": boolean;
    "geometry": GeoJSONPoint;
    "designId": string;
    "assetId"?: string;
}

@Component({
    components: {
        VBtn,
        VDialog,
        VCard,
        VCardTitle,
        VCardText,
        VCardActions,
        VDivider,
        VSpacer,
        VTextField,
        VFileInput,
        VProgressCircular,
    },
})
export default class GigastoreFileUpload extends tsx.Component<GigastoreFileUploadProps> {
    @Prop() value: boolean;
    @Prop() geometry: GeoJSONPoint;
    @Prop() designId: string;
    @Prop() assetId?: string;

    files?: File[];
    isUploading: boolean;
    fileUploadErrorMessage?: string;
    data() {
        return {
            files: undefined,
            fileUploadErrorMessage: undefined,
            isUploading: false,
        };
    }

    @Watch("files")
    onFilesChange(): void {
        this.fileUploadErrorMessage = undefined;
    }

    render(h: CreateElement): JSX.Element | undefined {
        const buttonContent = this.isUploading ? (
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        ) : (
            "Upload"
        );
        return (
            <div>
                <v-dialog v-model={this.value} max-width="500px" persistent>
                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2">Upload File/Photo</v-card-title>
                        <v-card-text>
                            <v-file-input
                                id="file-input"
                                accept="image/png, image/jpeg, image/bmp, application/pdf"
                                class="ml-2"
                                placeholder="Choose File"
                                v-model={this.files}
                                prepend-icon="mdi-paperclip"
                                clear-icon="mdi-close"
                                multiple
                                chips
                            ></v-file-input>
                            {this.fileUploadErrorMessage && (
                                <p class="file-upload-error">{this.fileUploadErrorMessage}</p>
                            )}
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn onClick={this.hideDialog}>Close</v-btn>
                            <v-btn
                                disabled={!this.files?.length || this.isUploading}
                                onClick={this.handleGigastoreUpload}
                                color="primary"
                            >
                                {buttonContent}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        );
    }

    toBase64(file: File): Promise<string | ArrayBuffer | null> {
        return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    async handleGigastoreUpload() {
        this.fileUploadErrorMessage = undefined;
        this.isUploading = true;
        if (!this.files?.length || !this.geometry) {
            this.isUploading = false;
            return;
        }
        const userName = await this.getUserName();
        const coordinates = projectToOSGB36(this.geometry.coordinates) as GeoJSONPosition;
        const geometry = stringify({
            type: this.geometry.type,
            coordinates,
        });
        const files = await Promise.all(
            this.files.map(async file => {
                const data = (await this.toBase64(file)) as string;

                return {
                    author: userName,
                    asset_id: this.assetId,
                    data: data.split(",")[1],
                    file_type: file.type,
                    filename: file.name,
                    design_id: this.designId,
                    source_location: geometry,
                };
            }),
        );
        try {
            const accessToken = accessTokenFromStore(this.$store) ?? "";
            const gigamapClient = gigamapApiFactory(accessToken);
            await gigamapClient.gigastoreCreateFiles(files);
            this.isUploading = false;
            this.files = undefined;
            this.hideDialog();
            showSnackbar("File upload complete");
        } catch (error) {
            switch (error.status) {
                case 409:
                    this.fileUploadErrorMessage =
                        "Conflict detected. Please make sure that this file hasn't been uploaded already";
                    break;
                case 400:
                    this.fileUploadErrorMessage = "Only PDF or images can be uploaded";
                    break;
                case 413:
                    this.fileUploadErrorMessage = "File size is too large";
                    break;
                default:
                    this.fileUploadErrorMessage = "Unknown error";
                    break;
            }
            if (error.status) {
                console.error(error);
            }
            this.isUploading = false;
        }
    }

    hideDialog() {
        this.$emit("input", false);
    }

    async getUserName(): Promise<string> {
        const authVersion = await getAuthVersion();
        let userData = null;
        if (authVersion === "AUTH_V1") {
            const [, payload] = getAuthToken();
            userData = payload;
        } else if (authVersion === "AUTH_V2") {
            userData = getUserData();
        }
        return userData ? userData.uid : "";
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-0053b18b/gigastore-file-upload.tsx" });