import { CreateElement } from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { VBtn, VCard, VCardTitle, VIcon, VSpacer } from "vuetify/lib";
import { State } from "vuex-class";

import * as store from "@/store";
import { CircleLayerSpecification, FillLayerSpecification, LineLayerSpecification } from "maplibre-gl";
import { LegendConfig } from "@/services/gigamap";
import { accessTokenFromStore } from "@/utils";

const ITEM_WIDTH = 20;
const ITEM_HEIGHT = 12;

type CirclePaint = CircleLayerSpecification["paint"];
type FillPaint = FillLayerSpecification["paint"];
type LinePaint = LineLayerSpecification["paint"];

function legendGraphicUrl(wmsUrl: URL, layer: string): string {
    return `${wmsUrl.origin}${wmsUrl.pathname}?request=GetLegendGraphic&layer=${layer}&format=image/png`;
}

interface LegendProps {
    mapStyle: maplibregl.StyleSpecification;
    legendConfig: LegendConfig;
}

interface LegendItem {
    img: string;
    label: string;
}

interface LegendMetadata {
    "fill-outline"?: boolean;
    "outline-color"?: string;
}

export function getRequestOptions(token: string): RequestInit {
    return { headers: { Authorization: `Bearer ${token}` } };
}

async function fetchImage(token: string, url: string): Promise<string> {
    const response = await fetch(url, getRequestOptions(token));
    if (response.status >= 400) {
        throw Error(response.statusText);
    }
    return URL.createObjectURL(await response.blob());
}

async function wmsLayerImages(
    token: string,
    style: maplibregl.StyleSpecification,
    sourceName: string,
): Promise<{ [key: string]: string }> {
    const source = style.sources?.[sourceName];
    if (source && source.type === "raster" && source.tiles) {
        const wmsUrl = new URL(source.tiles[0]);
        const layers = wmsUrl.searchParams.get("layers")?.split(",");
        if (layers) {
            const images = await Promise.all(layers.map(layer => fetchImage(token, legendGraphicUrl(wmsUrl, layer))));
            return Object.fromEntries(layers.map((layer, idx) => [layer, images[idx]]));
        }
    }
    return {};
}

interface SpriteLocation {
    height: number;
    pixelRatio: number;
    width: number;
    x: number;
    y: number;
}

interface SpriteIndex {
    [key: string]: SpriteLocation;
}

function getCanvasWithContext(height: number = ITEM_HEIGHT): [HTMLCanvasElement, CanvasRenderingContext2D | null] {
    const canvas = document.createElement("canvas") as HTMLCanvasElement;
    canvas.width = ITEM_WIDTH;
    canvas.height = height;
    return [canvas, canvas.getContext("2d")];
}

/**
 * Loads the sprite map and generates as series of images for each sprite.
 *
 * @param spritesUrl Sprites base url
 */
async function loadSprites(token: string, spritesUrl: string): Promise<{ [key: string]: string }> {
    const requestOptions = getRequestOptions(token);
    const [indexResponse, spriteMapObjectUrl] = await Promise.all([
        fetch(`${spritesUrl}.json`, requestOptions),
        fetchImage(token, `${spritesUrl}.png`),
    ]);
    const index = (await indexResponse.json()) as SpriteIndex;
    const spriteImage = new Image();
    spriteImage.src = spriteMapObjectUrl;
    return new Promise(resolve => {
        spriteImage.onload = () => {
            const sprites: { [key: string]: string } = {};
            for (const [name, location] of Object.entries(index)) {
                const [canvas, ctx] = getCanvasWithContext(location.height);
                if (ctx) {
                    ctx.drawImage(
                        spriteImage,
                        location.x,
                        location.y,
                        location.width,
                        location.height,
                        0,
                        0,
                        location.width,
                        location.height,
                    );
                    sprites[name] = canvas.toDataURL();
                }
            }
            resolve(sprites);
        };
    });
}

function drawCircle(size: number, fill: string, stroke: string | undefined, strokeWidth: number | undefined): string {
    const [canvas, ctx] = getCanvasWithContext();
    if (ctx) {
        ctx.fillStyle = fill;
        ctx.ellipse(ITEM_WIDTH / 2, ITEM_HEIGHT / 2, size, size, 0, 0, Math.PI * 2);
        ctx.fill();
        if (stroke && strokeWidth) {
            ctx.strokeStyle = stroke;
            ctx.lineCap = "round";
            ctx.lineWidth = strokeWidth;
            ctx.stroke();
        }
        return canvas.toDataURL();
    }
    return "";
}

function drawFill(fill: string, stroke?: string, dashArray?: number[]): string {
    const [canvas, ctx] = getCanvasWithContext();
    if (ctx) {
        ctx.fillStyle = fill;
        if (stroke) {
            ctx.strokeStyle = stroke;
            if (dashArray) {
                ctx.setLineDash(dashArray);
            }
            ctx.rect(1, 1, ITEM_WIDTH - 4, ITEM_HEIGHT - 2);
            ctx.stroke();
        } else {
            ctx.rect(0, 0, ITEM_HEIGHT, ITEM_HEIGHT);
        }
        ctx.fill();
        return canvas.toDataURL();
    }
    return "";
}

function drawLine(stroke: string, strokeWidth: number = 1, dashArray?: number[], outline?: string): string {
    const [canvas, ctx] = getCanvasWithContext();
    if (ctx) {
        if (outline) {
            ctx.strokeStyle = outline;
            ctx.lineWidth = strokeWidth + 2;
            ctx.moveTo(0, ITEM_HEIGHT / 2);
            ctx.lineTo(ITEM_WIDTH - 4, ITEM_HEIGHT / 2);
            ctx.stroke();
        }
        ctx.strokeStyle = stroke;
        ctx.lineWidth = strokeWidth;
        if (dashArray) {
            ctx.setLineDash(dashArray.map(x => x * strokeWidth));
        }
        ctx.moveTo(0, ITEM_HEIGHT / 2);
        ctx.lineTo(ITEM_WIDTH - 4, ITEM_HEIGHT / 2);
        ctx.stroke();
        return canvas.toDataURL();
    }
    return "";
}

function groupItems<T>(array: T[], size: number = 2): T[][] {
    return array.reduce((accum: T[][], item, idx) => {
        if (idx % size === 0) {
            accum.push([]);
        }
        accum[accum.length - 1].push(item);
        return accum;
    }, []);
}

function matchStatementPairs(array: any[]): any[][] {
    return groupItems(array.slice(2, -1), 2).concat([["default", array[array.length - 1]]]);
}

function getStringProperty<T>(paint: T, prop: keyof T): string | undefined {
    // Ignored due to this bug: https://github.com/microsoft/TypeScript/issues/10530
    // @ts-ignore
    return typeof paint[prop] === "string" ? paint[prop] : undefined;
}

function getNumberProperty<T>(paint: T, prop: keyof T): number | undefined {
    // Ignored due to this bug: https://github.com/microsoft/TypeScript/issues/10530
    // @ts-ignore
    return typeof paint[prop] === "number" ? paint[prop] : undefined;
}

function itemKey(layerId: string, matchLabel: string | number): string {
    return `${layerId}-${matchLabel.toString().toLocaleLowerCase().replaceAll(" ", "-")}`;
}

function circleLayerImages(layerId: string, paint: CirclePaint): { [key: string]: string } {
    const images: { [key: string]: string } = {};
    if (paint && typeof paint["circle-radius"] === "number") {
        if (typeof paint["circle-color"] === "string") {
            images[layerId] = drawCircle(
                paint["circle-radius"],
                paint["circle-color"],
                getStringProperty(paint, "circle-stroke-color"),
                getNumberProperty(paint, "circle-stroke-width"),
            );
        } else if (paint["circle-color"] instanceof Array) {
            if (paint["circle-color"][0] === "match") {
                const colorLabelPairs = matchStatementPairs(paint["circle-color"]) as Array<[string, string]>;
                for (const pair of colorLabelPairs) {
                    images[itemKey(layerId, pair[0])] = drawCircle(
                        paint["circle-radius"],
                        pair[1],
                        getStringProperty(paint, "circle-stroke-color"),
                        getNumberProperty(paint, "circle-stroke-width"),
                    );
                }
            }
        }
    }
    return images;
}

function fillLayerImages(layerId: string, paint: FillPaint): { [key: string]: string } {
    const images: { [key: string]: string } = {};
    if (paint) {
        if (typeof paint["fill-color"] === "string") {
            images[layerId] = drawFill(paint["fill-color"], getStringProperty(paint, "fill-outline-color"));
        } else if (paint["fill-color"] instanceof Array) {
            if (paint["fill-color"][0] === "match") {
                const colorLabelPairs = matchStatementPairs(paint["fill-color"]) as Array<[string, string]>;
                for (const pair of colorLabelPairs) {
                    images[itemKey(layerId, pair[0])] = drawFill(
                        pair[1],
                        getStringProperty(paint, "fill-outline-color"),
                    );
                }
            }
        }
    }
    return images;
}

function lineLayerImages(layerId: string, paint: LinePaint, metadata?: LegendMetadata): { [key: string]: string } {
    const images: { [key: string]: string } = {};
    if (paint) {
        if (typeof paint["line-color"] === "string") {
            if (metadata?.["fill-outline"]) {
                // @ts-ignore
                images[layerId] = drawFill("white", paint["line-color"], paint["line-dasharray"]);
            } else {
                images[layerId] = drawLine(
                    paint["line-color"],
                    getNumberProperty(paint, "line-width"),
                    // @ts-ignore
                    paint["line-dasharray"],
                    metadata?.["outline-color"],
                );
            }
        } else if (paint["line-color"] instanceof Array && paint["line-color"][0] === "match") {
            const colorLabelPairs = matchStatementPairs(paint["line-color"]) as Array<[string, string]>;
            for (const pair of colorLabelPairs) {
                images[itemKey(layerId, pair[0])] = drawLine(
                    pair[1],
                    getNumberProperty(paint, "line-width"),
                    // @ts-ignore
                    paint["line-dasharray"],
                    metadata?.["outline-color"],
                );
            }
        }
    }
    return images;
}

function renderImages(style: maplibregl.StyleSpecification): { [key: string]: string } {
    const images: { [key: string]: string } = {};
    if (style.layers) {
        for (const layer of style.layers) {
            if (layer.type === "circle" && layer.paint && "circle-radius" in layer.paint) {
                Object.assign(images, circleLayerImages(layer.id, layer.paint));
            }
            if (layer.type === "fill" && layer.paint && "fill-color" in layer.paint) {
                Object.assign(images, fillLayerImages(layer.id, layer.paint));
            }
            if (layer.type === "line" && layer.paint && "line-color" in layer.paint) {
                // @ts-ignore
                Object.assign(images, lineLayerImages(layer.id, layer.paint, layer.metadata?.legend));
            }
        }
    }
    return images;
}

@Component({
    components: {
        VBtn,
        VCard,
        VCardTitle,
        VIcon,
        VSpacer,
    },
})
export default class MapLegend extends tsx.Component<LegendProps> {
    @Prop()
    mapStyle: maplibregl.StyleSpecification;
    @Prop()
    legendConfig: LegendConfig;

    sprites?: { [key: string]: string };
    wmsLegendItems: LegendItem[];

    @State((s: store.State) => s.legendVisible) visible: boolean;

    data() {
        return {
            sprites: undefined,
            wmsLegendItems: [],
        };
    }

    mounted() {
        this.loadIcons();
    }

    async loadIcons() {
        const token = accessTokenFromStore(this.$store) ?? "";
        if (this.mapStyle.sprite && Object.keys(this.sprites ?? {}).length === 0) {
            this.sprites = Object.assign(
                {},
                await loadSprites(token, this.mapStyle.sprite),
                renderImages(this.mapStyle),
            );
            try {
                for (const source of this.legendConfig.raster_sources) {
                    this.sprites = Object.assign(this.sprites, await wmsLayerImages(token, this.mapStyle, source));
                }
            } catch (error) {
                if (!(error instanceof TypeError)) {
                    throw error;
                }
            }
        }
    }

    @Watch("visible")
    onVisible(visible: boolean) {
        if (visible) {
            this.loadIcons();
        }
    }

    legendItems(): JSX.Element[] {
        return this.legendConfig.groups.map(group => {
            const items = group.keys.map(key => {
                return (
                    <p class="legend-item">
                        <img src={this.sprites?.[key]}></img>
                        {this.legendConfig.items[key]}
                    </p>
                );
            });
            return (
                <div class="legend-group">
                    <h3>{group.heading}</h3>
                    {items}
                </div>
            );
        });
    }

    closeLegend() {
        store.setLegendVisibility(this.$store, false);
    }

    render(h: CreateElement): JSX.Element | undefined {
        if (this.visible) {
            return (
                <v-card class="sidepanel legend">
                    <v-card-title class="ma-0 mb-1 pa-0">
                        Legend
                        <v-spacer></v-spacer>
                        <v-btn icon onClick={this.closeLegend}>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    {this.legendItems()}
                </v-card>
            );
        }
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-c6138b1a/map-legend.tsx" });