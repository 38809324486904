import { Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { CreateElement } from "vue";

interface PageContainerProps {
    notFoundMessage?: string;
    notFoundError: boolean;
    forbiddenError: boolean;
}

@Component
export default class PageContainer extends tsx.Component<PageContainerProps> {
    @Prop({ default: "The requested item was not found" })
    notFoundMessage: string;
    @Prop({ default: false }) notFoundError: boolean;
    @Prop({ default: false }) forbiddenError: boolean;

    render(h: CreateElement) {
        if (this.forbiddenError) {
            return (
                <div style="text-align: center; padding: 15px">
                    <h1>Access Forbidden</h1>
                    <p>You do not currently have permission to view / perform this action.</p>
                    <p>
                        Please contact the IT team if you require access:
                        <a href="mailto:it-servicedesk@gigaclear.com">it-servicedesk@gigaclear.com</a>
                    </p>
                </div>
            );
        } else if (this.notFoundError) {
            return (
                <div style="text-align: center; padding: 15px">
                    <h1>{this.notFoundMessage}</h1>
                    <p>Please check the address is correct and try again</p>
                </div>
            );
        } else {
            return <slot>{this.$slots.default}</slot>;
        }
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-10783c18/page-container.tsx" });