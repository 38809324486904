import ExportChangesControl from "@/export-changes-control";
import { assetDbApiFactory } from "@/services";
import {
    AccessCabinet,
    Atlas,
    AtlasPage,
    BuildingAggregationPoint,
    Cable,
    Chamber,
    Closure,
    DropCabinet,
    GatewayCabinet,
    Multiduct,
    Pole,
    Port,
    Pot,
    ServicePoint,
    Splice,
    Splitter,
    SweptTee,
    Trench,
    WorkPackage,
    Workpoint,
    AssetCollection,
    Changeset,
    ChangesetMetadata,
    NOIOrder,
    Note,
    SED,
} from "@/services/asset_db";
import { accessTokenFromStore, showSnackbar } from "@/utils";
import MapboxDraw from "@gc/mapbox-gl-draw";
import { Feature, FeatureCollection } from "geojson";
import { CreateElement } from "vue";
import { Component } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import {
    VTextField,
    VBtn,
    VDialog,
    VStepper,
    VStepperStep,
    VStepperContent,
    VForm,
    VList,
    VListGroup,
    VListItemContent,
    VListItem,
    VListItemTitle,
    VCard,
    VCardTitle,
    VProgressCircular,
} from "vuetify/lib";
import * as store from "@/store";
import { toWkt } from "./asset-data-popup";
import { State } from "vuex-class";
import { FeatureInfo } from "@/services/gigamap";

type Asset =
    | AccessCabinet
    | AtlasPage
    | Atlas
    | BuildingAggregationPoint
    | Cable
    | Chamber
    | Closure
    | DropCabinet
    | GatewayCabinet
    | Multiduct
    | NOIOrder
    | Note
    | Pole
    | Port
    | Pot
    | SED
    | ServicePoint
    | Splice
    | Splitter
    | SweptTee
    | Trench
    | WorkPackage
    | Workpoint;

function noteFromFeature(feature: Feature): Note {
    return {
        id: feature.properties?.id,
        geometry: toWkt(feature.geometry),
        cabinet_areas: [feature.properties?.cabinet_area],
        name: "",
        text: feature.properties?.text,
    };
}

function noiPolygonFromFeature(feature: Feature): NOIOrder {
    return {
        id: feature.properties?.id,
        geometry: toWkt(feature.geometry),
        cabinet_areas: [feature.properties?.cabinet_area],
        name: "",
        noi_number: feature.properties?.noi_number,
    };
}

function sedFromFeature(feature: Feature): SED {
    return {
        id: feature.properties?.id,
        geometry: toWkt(feature.geometry),
        cabinet_areas: [feature.properties?.cabinet_area],
        name: "",
        text: feature.properties?.text,
    };
}

function changesetFromFeatures(
    newFeatures: Feature[],
    metadata: ChangesetMetadata,
    updates?: AssetCollection,
): Changeset {
    const inserts: AssetCollection = {
        notes: [],
        noi_orders: [],
        seds: [],
    };
    for (const feat of newFeatures) {
        switch (feat.properties?.type) {
            case "note":
                inserts.notes?.push(noteFromFeature(feat));
                break;
            case "sed":
                inserts.seds?.push(sedFromFeature(feat));
                break;
            case "noi_order":
                inserts.noi_orders?.push(noiPolygonFromFeature(feat));
                break;
            default:
                continue;
        }
    }
    return {
        metadata,
        inserts,
        updates,
    };
}

function applyAssetChanges(assets: AssetCollection, featureEdits: FeatureInfo[]): AssetCollection {
    const assetsById: { [key: string]: [keyof AssetCollection, Asset] } = {};
    const assetCollection: AssetCollection = {};
    for (const [type, assetList] of Object.entries(assets) as Array<[keyof AssetCollection, Asset[]]>) {
        for (const asset of assetList) {
            assetsById[asset.id] = [type, asset];
        }
    }

    for (const feat of featureEdits) {
        if (feat.id) {
            const [type, asset] = assetsById[feat.id];
            for (const attr of feat.properties) {
                // @ts-ignore
                asset[attr.key] = attr.value;
            }
            if (!(type in assetCollection)) {
                assetCollection[type] = [];
            }
            // @ts-ignore
            assetCollection[type].push(asset);
        }
    }
    return assetCollection;
}

@Component({
    components: {
        VCard,
        VCardTitle,
        VTextField,
        VBtn,
        VDialog,
        VStepper,
        VStepperContent,
        VStepperStep,
        VForm,
        VList,
        VListGroup,
        VListItemContent,
        VListItem,
        VListItemTitle,
        VProgressCircular,
    },
})
export class ChangesetDialog extends tsx.Component<{}> {
    visible: boolean;
    features?: FeatureCollection;
    step: number;
    message: string;
    submitting: boolean;
    mapDraw?: MapboxDraw;
    exportChangesControl?: ExportChangesControl;

    @State((s: store.State) => s.featureEdits) featureEdits: { [key: string]: FeatureInfo };

    data() {
        return {
            visible: false,
            step: 1,
            features: undefined,
            message: "",
            submitting: false,
            mapDraw: undefined,
            exportChangesControl: undefined,
        };
    }

    async submitChangeset() {
        if (this.features && this.mapDraw) {
            const token = accessTokenFromStore(this.$store) ?? "";
            const client = assetDbApiFactory(token);
            let updatedAssets: AssetCollection | undefined;
            // Fetch complete asset data from asset-db
            if (Object.values(this.featureEdits).length > 0) {
                const assets = await client.getAssetsByIds(Object.keys(this.featureEdits));
                updatedAssets = applyAssetChanges(assets.assets, Object.values(this.featureEdits));
            }
            const changeset = changesetFromFeatures(this.features?.features, { message: this.message }, updatedAssets);
            this.submitting = true;
            try {
                const changesetCommit = await client.commitChangeset(changeset);
                // Remove all committed features
                for (const feat of this.features.features) {
                    if (typeof feat.id === "string" && feat.properties?.type !== "redline") {
                        this.mapDraw.delete(feat.id);
                    }
                }
                if (this.mapDraw.getAll().features.length === 0 && this.exportChangesControl) {
                    this.exportChangesControl.setEnabled(false, false);
                }
                showSnackbar(`Created changeset ${changesetCommit.id}. Changes may take a few minutes to be visible.`);
                this.visible = false;
            } finally {
                this.submitting = false;
            }
        }
    }

    open(features: FeatureCollection, mapDraw: MapboxDraw, exportChangesControl: ExportChangesControl) {
        this.features = features;
        this.visible = true;
        this.step = 1;
        this.mapDraw = mapDraw;
        this.exportChangesControl = exportChangesControl;
    }

    render(h: CreateElement): JSX.Element {
        const labels: { [key: string]: string } = {
            note: "Notes",
            noi_order: "NOI Polygons",
            sed: "SEDs",
        };
        const newFeatureCounts: { [key: string]: number } = {};
        for (const feat of this.features?.features ?? []) {
            const featType = feat.properties?.type;
            if (typeof featType === "string" && featType in labels) {
                if (featType in newFeatureCounts) {
                    newFeatureCounts[featType] += 1;
                } else {
                    newFeatureCounts[featType] = 1;
                }
            }
        }
        const newFeatCount = this.features?.features.filter(feat => feat.properties?.type in labels).length ?? 0;
        const updatedFeatureCount = Object.values(this.featureEdits).length;
        const newFeatureItems = Object.entries(newFeatureCounts).map(([key, value]) => {
            return (
                <v-list-item>
                    <v-list-item-title>
                        {labels[key]} - {value}
                    </v-list-item-title>
                </v-list-item>
            );
        });
        return (
            <v-dialog max-width="500px" v-model={this.visible}>
                <v-card>
                    <v-card-title>Create Changeset</v-card-title>
                    <v-stepper v-model={this.step}>
                        <v-stepper-step complete={this.step > 1} step="1">
                            Review Changes
                        </v-stepper-step>
                        <v-stepper-content step="1" style="padding-top: 0px">
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>Updated Assets - {updatedFeatureCount}</v-list-item-title>
                                </v-list-item>
                                <v-list-group value={true}>
                                    <v-list-item-title slot="activator">New Assets - {newFeatCount}</v-list-item-title>
                                    {newFeatureItems}
                                </v-list-group>
                            </v-list>
                            <v-btn onClick={() => (this.step = 2)}>Continue</v-btn>
                        </v-stepper-content>
                        <v-stepper-step step="2">Changeset</v-stepper-step>
                        <v-stepper-content step="2" style="padding-top: 0px">
                            <v-text-field v-model={this.message} label="Message"></v-text-field>
                            <v-btn onClick={() => (this.step = 1)} class="mr-2">
                                Back
                            </v-btn>
                            <v-btn enabled={!this.submitting} onClick={this.submitChangeset}>
                                {this.submitting ? (
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                ) : (
                                    "Submit"
                                )}
                            </v-btn>
                        </v-stepper-content>
                    </v-stepper>
                </v-card>
            </v-dialog>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-22775ee8/changeset-dialogue.tsx" });