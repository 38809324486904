import { apiUrl } from "./appconfig";
import { getAccessToken } from "./oauth";
import { getAuthToken } from "@/services";

export interface AuthConfigs {
    authVersion?: string;
    authServiceUrl?: string;
    applicationId?: string;
    tenantId?: string;
    authority?: string;
    redirectUri?: string;
    loginScopes?: string[];
    tokenScopes?: string[];
}

let authVersion: string = "";

// Get configs from an unauthenticated API endpoint
export async function getAuthConfigs(url: string) {
    const response = await fetch(url, { cache: "no-cache" });
    if (!response.ok || response.status !== 200) {
        throw new Error(`Failed to fetch auth configs: ${response.statusText}`);
    }
    try {
        const configs = await response.json();
        const authConfigs: AuthConfigs = {
            authVersion: configs.auth_version,
            authServiceUrl: configs.auth_service_url,
            applicationId: configs.application_id || "",
            tenantId: configs.tenant_id || "",
            authority: configs.authority,
            redirectUri: configs.redirect_uri,
            loginScopes: configs.login_scopes,
            tokenScopes: configs.access_token_scopes,
        };
        return authConfigs;
    } catch (error) {
        throw new Error(`Failed to parse auth configs as JSON: ${error}`);
    }
}

export async function getHealthCheck(url: string, accessToken: string) {
    try {
        const healthCheck = await fetch(url, {
            headers: { Authorization: "Bearer " + accessToken },
        });
        if (healthCheck.status >= 200 && healthCheck.status < 300) {
            const response = await healthCheck.json();
            return response;
        }
    } catch (error) {
        return error.message;
    }
}

export function setAuthVersion(version: string) {
    authVersion = version;
}

export async function getAuthVersion() {
    if (authVersion === "AUTH_V1" || authVersion === "AUTH_V2") {
        return authVersion;
    } else {
        const authConfigs: any = await getAuthConfigs(apiUrl() + "/authConfigs");
        authVersion = authConfigs.authVersion;
        return authVersion;
    }
}

export async function getAuthorizationToken() {
    let apiToken = null;
    const authType = await getAuthVersion();
    if (authType === "AUTH_V1") {
        const [jwt] = getAuthToken();
        apiToken = jwt;
    } else if (authType === "AUTH_V2") {
        apiToken = await getAccessToken();
    } else {
        throw new Error("Unexpected Authorization Type");
    }
    return apiToken;
}
