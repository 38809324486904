import "@mdi/font/css/materialdesignicons.css";
import { ApmVuePlugin } from "@elastic/apm-rum-vue";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import * as Vuex from "vuex";
import App from "@/app";
import router from "@/router";
import { createStore } from "@/store";
import { messages } from "@/services";
import { getAuthorizationToken } from "@/auth-helpers";
import "./registerServiceWorker";
import { isDev, APP_NAME, getEnvironmentFromHostname, readConfig, APP_VERSION } from "@/appconfig";
import colors from "vuetify/es5/util/colors";
import "@/override.css";
import { hasUnsavedChanges } from "./utils";

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(Vuetify);

const store = createStore();

window.onerror = (message, file, line, col, error) => {
    if (error) {
        reportError(error);
    }
};

window.onbeforeunload = (event: BeforeUnloadEvent) => {
    if (hasUnsavedChanges()) {
        event.preventDefault();
        return (event.returnValue = "There are unsaved changes present, are you sure you want to leave?");
    }
};

Vue.config.errorHandler = (err: Error, vm: Vue, info: string) => {
    if (err instanceof Response) {
        apiErrorHandler(err);
    } else {
        reportError(err);
    }
};

function reportError(error: Error) {
    console.error(error);
}

function apiErrorHandler(r: Response) {
    if (r.status === 401) {
        messages.$emit("unauthorized", r);
    } else if (r.status === 403) {
        messages.$emit("forbidden", r);
    } else if (r.status === 404) {
        messages.$emit("notFoundError");
    } else if (r.status === 400) {
        messages.$emit("apiError", "Server failed to validate the request");
    } else if (r.status === 409) {
        messages.$emit("apiError", "Conflicting changes detected");
    } else {
        messages.$emit("apiError", `Unexpected error from the server: ${r.status}`);
    }
}

const vuetifyOpts = {
    theme: {
        themes: {
            light: {
                primary: isDev() ? colors.purple.darken2 : "#FF8200",
                secondary: isDev() ? "#be6be1" : "#9b9b9b",
                accent: "#4527A0",
                error: "#f44336",
                warning: "#FFD600",
                info: "#2196f3",
                success: "#4caf50",
            },
        },
    },
};

router.beforeEach(async (to, _, next) => {
    const routes = to.matched.slice().reverse();
    const routeWithTitle = routes.find(r => r.meta && r.meta.title);

    if (routeWithTitle) {
        document.title = routeWithTitle.meta.title;
    } else {
        document.title = APP_NAME;
    }

    try {
        const payload = await getAuthorizationToken();
        if (payload || to.path === "/login") {
            next();
        } else {
            messages.$emit("loggedOut");
            next({ path: "/login", query: { redirect: to.fullPath } });
        }
    } catch (error) {
        if (!(error instanceof TypeError)) {
            throw error;
        }
        next();
    }
});

readConfig().then(config => {
    if (config.ELASTIC_APM_ENABLED) {
        Vue.use(ApmVuePlugin, {
            router,
            // Agent configuration
            config: {
                serviceName: "vector-gigamap-ui",
                active: config.ELASTIC_APM_ENABLED,
                serverUrl: config.ELASTIC_APM_SERVER_ORIGIN,
                environment: config.ELASTIC_APM_ENVIRONMENT ?? getEnvironmentFromHostname(),
                serviceVersion: APP_VERSION,
                // Origins other than itself, match everything (just the Auth Service in theory)
                distributedTracingOrigins: [/.*/],
            },
        });
    }
});

export const vuetify = new Vuetify(vuetifyOpts);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount("#app");
