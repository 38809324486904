import * as Vuex from "vuex";
import { getStoreAccessors } from "vuex-typescript";

import { Config, readConfig } from "@/appconfig";
import { FeatureInfo } from "@/services/gigamap";
import { CabinetAreaSummary } from "@/services/asset_db";

enum Panels {
    LayersControl = "layers-control",
    Legend = "legend",
    SearchResults = "search-results",
    WayleaveFrame = "wayleave-frame",
}

export interface Property {
    key: string;
    description: string;
    editable?: boolean;
}
export interface Layer {
    visible: boolean;
    layers: string[];
    properties: Property[];
    label: string;
    source?: string;
    group?: string;
}

export interface Layers {
    [key: string]: Layer;
}

export interface VisibilityUpdate {
    layer: string;
    visible: boolean;
}

export interface SearchResults {
    nonce: number;
    results: { [key: string]: FeatureInfo[] };
}

export interface State {
    authVersion: string;
    authServiceUrl: string;
    msalConfig: any;
    loginScopes?: string[];
    accessTokenScopes?: string[];
    accessToken?: string;
    config?: Config;
    username?: string;
    cablePathVisible: boolean;
    layers: Layers;
    layersControlVisible: boolean;
    legendVisible: boolean;
    splitFrameVisible: boolean;
    splitPanelURL: string;
    searchResultsVisible: boolean;
    searchResults?: SearchResults;
    cabAreas: CabinetAreaSummary[];
    gigastoreUploadDialogVisible: boolean;
    gigastoreUploadMarkerPosition?: [number, number];
    featureEdits: { [key: string]: FeatureInfo };
}

type Context = Vuex.ActionContext<State, State>;

const state: State = {
    authVersion: "",
    authServiceUrl: "",
    msalConfig: {
        auth: {
            clientId: "",
            authority: "",
        },
        cache: {
            cacheLocation: "localStorage",
        },
    },
    loginScopes: [],
    // It's important to set this to a scope for the API, otherwise the signature will not validate
    accessTokenScopes: [],
    accessToken: undefined,
    config: undefined,
    username: undefined,
    cablePathVisible: false,
    layers: {},
    layersControlVisible: false,
    legendVisible: false,
    splitFrameVisible: false,
    splitPanelURL: "",
    searchResultsVisible: false,
    searchResults: undefined,
    gigastoreUploadDialogVisible: false,
    gigastoreUploadMarkerPosition: undefined,
    cabAreas: [],
    featureEdits: {},
};

const { commit, dispatch } = getStoreAccessors<State, State>("");

const getters = {
    config(s: State): Config | undefined {
        return s.config;
    },
    layers(s: State): Layers {
        return s.layers;
    },
    cablePathVisible(s: State): boolean {
        return s.cablePathVisible;
    },
};

const actions = {
    async loadConfig(context: Context): Promise<Config> {
        let config = getters.config(context.state);
        if (!!config) {
            return config;
        }
        config = await readConfig();
        commit(mutations.setConfig)(context, config);
        return config;
    },
    setUsername(context: Context, username: string) {
        commit(mutations.setUsername)(context, username);
    },
    setAccessToken(context: Context, accessToken: string) {
        commit(mutations.setAccessToken)(context, accessToken);
    },
    setLoginScopes(context: Context, scopes: string[]) {
        commit(mutations.setLoginScopes)(context, scopes);
    },
    setAccessTokenScopes(context: Context, scopes: string[]) {
        commit(mutations.setAccessTokenScopes)(context, scopes);
    },
    setMsalConfigs(context: Context, configs: any) {
        commit(mutations.setMsalConfigs)(context, configs);
    },
    setAuthServiceConfigs(context: Context, configs: any) {
        commit(mutations.setAuthServiceConfigs)(context, configs);
    },
    setLayers(context: Context, layers: Layers) {
        commit(mutations.setLayers)(context, layers);
    },
    setLayerVisibility(context: Context, update: VisibilityUpdate) {
        commit(mutations.setLayerVisibility)(context, update);
    },
    setLayerControlVisibility(context: Context, value: boolean) {
        commit(mutations.setActivePanel)(context, value ? Panels.LayersControl : undefined);
    },
    setLegendVisibility(context: Context, value: boolean) {
        commit(mutations.setActivePanel)(context, value ? Panels.Legend : undefined);
    },
    setSearchResultsVisibility(context: Context, value: boolean) {
        commit(mutations.setActivePanel)(context, value ? Panels.SearchResults : undefined);
    },
    setSplitFrameVisibility(context: Context, value: boolean) {
        commit(mutations.setSplitFramePanelVisible)(context, value);
    },
    setCablePathVisible(context: Context, value: boolean) {
        commit(mutations.setCablePathVisible)(context, value);
    },
    setSplitPanelURL(context: Context, value: string) {
        commit(mutations.setSplitPanelURL)(context, value);
    },
    setActivePanel(context: Context, panel: Panels | undefined) {
        commit(mutations.setActivePanel)(context, panel);
    },
    setSearchResults(context: Context, results: SearchResults) {
        const current = context.state.searchResults;
        commit(mutations.setActivePanel)(context, Panels.SearchResults);
        if (current === undefined || current.nonce < results.nonce) {
            commit(mutations.setSearchResults)(context, results);
        }
    },
    clearSearchResults(context: Context) {
        if (context.state.searchResultsVisible) {
            commit(mutations.setActivePanel)(context, undefined);
        }
        commit(mutations.clearSearchResults)(context);
    },
    setGigastoreUploadDialogVisibility(context: Context, isVisible: boolean) {
        commit(mutations.setGigastoreUploadDialogVisibility)(context, isVisible);
    },
    setGigastoreUploadMarkerPosition(context: Context, position: [number, number] | undefined) {
        commit(mutations.setGigastoreUploadMarkerPosition)(context, position);
    },
    setCabinetAreas(context: Context, cabAreas: CabinetAreaSummary[]) {
        commit(mutations.setCabinetAreas)(context, cabAreas);
    },
    addFeatureEdit(context: Context, feature: FeatureInfo) {
        commit(mutations.addFeatureEdit)(context, feature);
    },
    clearFeatureEdits(context: Context) {
        commit(mutations.clearFeatureEdits)(context);
    },
    setFeatureEdits(context: Context, features: FeatureInfo[]) {
        commit(mutations.setFeatureEdits)(context, features);
    },
};

const mutations = {
    setConfig(s: State, config: Config) {
        s.config = config;
    },
    setUsername(s: State, username: string) {
        s.username = username;
    },
    setAccessToken(s: State, accessToken: string) {
        s.accessToken = accessToken;
    },
    setCabinetAreas(s: State, cabAreas: CabinetAreaSummary[]) {
        s.cabAreas = cabAreas;
    },
    setMsalConfigs(s: State, configs: any) {
        s.authVersion = "AUTH_V2";
        if (configs.applicationId) {
            s.msalConfig.auth.clientId = configs.applicationId;
        }
        if (configs.authority) {
            s.msalConfig.auth.authority = configs.authority;
        } else if (configs.tenantId) {
            s.msalConfig.auth.authority = `https://login.microsoftonline.com/${configs.tenantId}`;
        }
        if (configs.redirectUri) {
            // By default, MSAL is configured to set the redirect URI to the current page that it is running on.
            // We only need this config if we want to receive the auth code on a different page.
            s.msalConfig.auth.redirectUri = configs.redirectUri;
        }
        if (configs.loginScopes) {
            s.loginScopes = configs.loginScopes;
        }
        if (configs.tokenScopes) {
            s.accessTokenScopes = configs.tokenScopes;
        } else if (configs.applicationId) {
            s.accessTokenScopes = [`api://${configs.applicationId}/Main`];
        }
    },
    setAuthServiceConfigs(s: State, configs: any) {
        s.authVersion = "AUTH_V1";
        s.authServiceUrl = configs.authServiceUrl;
    },
    setLoginScopes(s: State, scopes: string[]) {
        s.loginScopes = scopes;
    },
    setAccessTokenScopes(s: State, scopes: string[]) {
        s.accessTokenScopes = scopes;
    },
    setLayers(s: State, layers: Layers) {
        s.layers = layers;
    },
    setLayerVisibility(s: State, update: VisibilityUpdate) {
        s.layers[update.layer].visible = update.visible;
    },
    setActivePanel(s: State, panel: Panels | undefined) {
        s.layersControlVisible = panel === Panels.LayersControl;
        s.legendVisible = panel === Panels.Legend;
        s.searchResultsVisible = panel === Panels.SearchResults;
    },
    setSplitFramePanelVisible(s: State, isSet: boolean) {
        s.splitFrameVisible = isSet;
    },
    setCablePathVisible(s: State, isVisible: boolean) {
        s.cablePathVisible = isVisible;
    },
    setSplitPanelURL(s: State, url: string) {
        s.splitPanelURL = url;
    },
    setSearchResults(s: State, results: SearchResults) {
        s.searchResults = results;
    },
    clearSearchResults(s: State) {
        s.searchResults = undefined;
    },
    setGigastoreUploadDialogVisibility(s: State, isVisible: boolean) {
        s.gigastoreUploadDialogVisible = isVisible;
    },
    setGigastoreUploadMarkerPosition(s: State, position: [number, number] | undefined) {
        s.gigastoreUploadMarkerPosition = position;
    },
    addFeatureEdit(s: State, feature: FeatureInfo) {
        if (feature?.id) {
            s.featureEdits[feature.id] = feature;
        }
    },
    clearFeatureEdits(s: State) {
        s.featureEdits = {};
    },
    setFeatureEdits(s: State, features: FeatureInfo[]) {
        s.featureEdits = Object.fromEntries(features.map(feat => [feat.id, feat]));
    },
};

export const createStore = (initialState: State = state) =>
    new Vuex.Store<State>({
        state: initialState,
        getters,
        mutations,
        actions,
    });

export const loadConfig = dispatch(actions.loadConfig);
export const setUsername = dispatch(actions.setUsername);
export const setAccessToken = dispatch(actions.setAccessToken);
export const setLoginScopes = dispatch(actions.setLoginScopes);
export const setAccessTokenScopes = dispatch(actions.setAccessTokenScopes);
export const setMsalConfigs = dispatch(actions.setMsalConfigs);
export const setAuthServiceConfigs = dispatch(actions.setAuthServiceConfigs);
export const setLayers = dispatch(actions.setLayers);
export const setLayerVisibility = dispatch(actions.setLayerVisibility);
export const setLayerControlVisibility = dispatch(actions.setLayerControlVisibility);
export const setLegendVisibility = dispatch(actions.setLegendVisibility);
export const setSplitFrameVisibility = dispatch(actions.setSplitFrameVisibility);
export const setSplitPanelURL = dispatch(actions.setSplitPanelURL);
export const setSearchResultsVisibility = dispatch(actions.setSearchResultsVisibility);
export const setActivePanel = dispatch(actions.setActivePanel);
export const setSearchResults = dispatch(actions.setSearchResults);
export const clearSearchResults = dispatch(actions.clearSearchResults);
export const setGigastoreUploadDialogVisibility = dispatch(actions.setGigastoreUploadDialogVisibility);
export const setGigastoreUploadMarkerPosition = dispatch(actions.setGigastoreUploadMarkerPosition);
export const setCabinetAreas = dispatch(actions.setCabinetAreas);
export const addFeatureEdit = dispatch(actions.addFeatureEdit);
export const clearFeatureEdits = dispatch(actions.clearFeatureEdits);
export const setFeatureEdits = dispatch(actions.setFeatureEdits);
export const setCablePathVisible = dispatch(actions.setCablePathVisible);
