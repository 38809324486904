import Vue, { CreateElement } from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class NotFound extends Vue {
    render(h: CreateElement) {
        return (
            <div class="ma-4">
                <h1>Not found...</h1>
                <p>Sorry :(</p>
            </div>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-7e7d1ea3/not-found.tsx" });