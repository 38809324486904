import Vue, { CreateElement } from "vue";
import { Component } from "vue-property-decorator";
import jwt_decode from "jwt-decode";

import initAuth, { getAccessToken, getUserData, login, UserIdPayload } from "@/oauth";
import { getAuthConfigs } from "@/auth-helpers";
import { TOKEN_KEY, TokenPayload, messages, authApiFactory } from "@/services";
import { apiUrl } from "@/appconfig";
import { VCard, VCardTitle, VCardActions, VSpacer, VBtn, VSnackbar, VTextField } from "vuetify/lib";
import { setMsalConfigs, setAuthServiceConfigs } from "@/store";
import { objectToUrlSearchParams } from "@/services/common";

@Component({
    components: {
        VCard,
        VCardTitle,
        VCardActions,
        VSpacer,
        VBtn,
        VSnackbar,
        VTextField,
    },
})
export default class Login extends Vue {
    loginErrorMessage: string;
    loginFailed: boolean;
    username: string;
    password: string;
    authV1LoginRequired: boolean;
    authV2LoginRequired: boolean;

    data() {
        return {
            loginErrorMessage: "",
            loginFailed: false,
            username: "",
            password: "",
            authV1LoginRequired: false,
            authV2LoginRequired: false,
        };
    }

    async handleLogin(token: string) {
        // POST token to login endpoint to set token cookie
        await fetch(apiUrl() + "/login", {
            method: "POST",
            body: objectToUrlSearchParams({ token, redirect: "" }),
            credentials: "include",
            mode: "cors",
            redirect: "manual",
        });
    }

    async mounted() {
        let authConfigs: any = null;
        try {
            authConfigs = await getAuthConfigs(apiUrl() + "/authConfigs");
            if (authConfigs.authVersion === "AUTH_V1") {
                setAuthServiceConfigs(this.$store, authConfigs);
            } else if (authConfigs.authVersion === "AUTH_V2") {
                setMsalConfigs(this.$store, authConfigs);
                initAuth(
                    this.$store.state.msalConfig,
                    this.$store.state.loginScopes,
                    this.$store.state.accessTokenScopes,
                );
            }
        } catch (error) {
            messages.$emit("apiError", error.message);
            return;
        }

        if (authConfigs.authVersion === "AUTH_V1") {
            const api = authApiFactory(this.$store.state.authServiceUrl, { credentials: "include" });
            try {
                const tokenResponse = await api.getToken();
                const token = await tokenResponse.text();
                await this.handleLogin(token);
                this.handleV1Token(token);
            } catch (errorResponse) {
                this.authV1LoginRequired = true;
                this.authV2LoginRequired = false;
                if (errorResponse.status !== 422) {
                    throw errorResponse;
                }
            }
        } else if (authConfigs.authVersion === "AUTH_V2") {
            const token = await getAccessToken();
            if (token) {
                await this.handleLogin(token);
                this.handleV2Token(null);
            } else {
                this.authV2LoginRequired = true;
                this.authV1LoginRequired = false;
            }
        }
    }

    render(h: CreateElement) {
        return (
            <div id="loginView">
                {!this.authV2LoginRequired && !this.authV1LoginRequired && (
                    <v-card id="authMessage" class="login-card">
                        <v-card-title>
                            <h3>Authenticating...</h3>
                        </v-card-title>
                    </v-card>
                )}
                {this.authV2LoginRequired && (
                    <v-card class="login-card">
                        <form onSubmit={this.authV2SignIn}>
                            <v-card-title>
                                <h3>Login Required</h3>
                            </v-card-title>
                            <div class="mx-4">
                                <p>Sign in using your email</p>
                            </div>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text id="login" color="primary" type="submit">
                                    Login
                                </v-btn>
                            </v-card-actions>
                        </form>
                    </v-card>
                )}
                {this.authV1LoginRequired && (
                    <v-card class="login-card">
                        <form onSubmit={this.authV1SignIn}>
                            <v-card-title>
                                <h3>Login Required</h3>
                            </v-card-title>
                            <div class="mx-4">
                                <v-text-field
                                    id="username-input"
                                    label="Username"
                                    v-model={this.username}
                                    required
                                ></v-text-field>
                                <v-text-field
                                    id="password-input"
                                    label="Password"
                                    type="password"
                                    v-model={this.password}
                                    required
                                ></v-text-field>
                            </div>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text id="login" color="primary" type="submit">
                                    Login
                                </v-btn>
                            </v-card-actions>
                        </form>
                    </v-card>
                )}
                <v-snackbar bottom v-model={this.loginFailed} timeout={60000}>
                    <span>{this.loginErrorMessage}</span>
                    <v-btn text color="primary" onClick={() => (this.loginFailed = false)}>
                        Close
                    </v-btn>
                </v-snackbar>
            </div>
        );
    }

    async authV1SignIn(event: Event) {
        event.preventDefault();
        const api = authApiFactory(this.$store.state.authServiceUrl, { credentials: "include" });
        try {
            const tokenResponse = await api.createToken(this.username, this.password);
            const token = await tokenResponse.text();
            await this.handleLogin(token);
            this.handleV1Token(token);
        } catch (errorResponse) {
            if (errorResponse.status === 401) {
                this.loginErrorMessage = "Login failed: Username or password incorrect";
                this.loginFailed = true;
            } else {
                throw errorResponse;
            }
        }
    }

    async authV2SignIn(event: Event) {
        event.preventDefault();
        const loginResponse = await login(this.$store.state.loginScopes);
        if (typeof loginResponse === "string") {
            this.loginErrorMessage = loginResponse;
            this.loginFailed = true;
        } else {
            this.handleV2Token(loginResponse);
        }
    }

    async handleV1Token(token: string) {
        const redirectTo = (this.$route.query.redirect as string) || "/";
        const payload: TokenPayload = jwt_decode(token);
        this.authV1LoginRequired = false;
        sessionStorage.setItem(TOKEN_KEY, token);
        messages.$emit("login", payload);
        this.$router.push(redirectTo);
    }

    async handleV2Token(userData: UserIdPayload | null) {
        const redirectTo = (this.$route.query.redirect as string) || "/";
        if (!userData) {
            userData = getUserData();
        }
        this.authV2LoginRequired = false;
        messages.$emit("login", userData);
        this.$router.push(redirectTo);
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-69e12184/login.tsx" });