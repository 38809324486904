import { Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";

interface SvgIconLoadProps {
    src: string;
    color: string;
}

@Component({})
export default class SvgIconLoad extends tsx.Component<SvgIconLoadProps> {
    @Prop() src: string;
    @Prop() color: string;

    svgContent: string = "";

    async loadSVG() {
        try {
            const response = await fetch(this.src);
            if (!response.ok) {
                throw new Error(`Failed to fetch SVG: ${response.statusText}`);
            }
            this.svgContent = await response.text();
            this.applyColor();
        } catch (error) {
            console.error("Error loading SVG:", error);
        }
    }

    applyColor() {
        this.svgContent = this.svgContent.replace(/fill="[^"]*"/g, `fill="${this.color}"`);
        this.svgContent = this.svgContent.replace(/stroke="[^"]*"/g, `stroke="${this.color}"`);
    }

    created() {
        this.loadSVG();
    }

    render(h: any) {
        return h("div", {
            style: {
                width: "24px",
                height: "24px",
            },
            domProps: { innerHTML: this.svgContent },
        });
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-69f6717c/svg-file-icon.tsx" });