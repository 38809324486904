import maplibregl, { IControl } from "maplibre-gl";

export default class StreetViewControl implements IControl {
    map: maplibregl.Map;
    mapMinZoom: number;
    btn: HTMLButtonElement;
    container: HTMLDivElement;
    marker?: maplibregl.Marker;

    onClicked() {
        if (this.marker) {
            const lngLat = this.marker.getLngLat();
            const url = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lngLat.lat},${lngLat.lng}`;
            window.open(url, "_blank");
            this.disable();
        }
    }

    disable() {
        this.btn.classList.remove("active");
        if (this.marker) {
            this.marker.remove();
            this.marker = undefined;
        }
    }

    onAdd(map: maplibregl.Map) {
        this.map = map;

        this.btn = document.createElement("button");
        this.btn.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" enable-background="new 0 0 24 24"viewBox="0 0 24 24">
            <g><rect fill="none" height="24" width="24"/></g>
                <g>
                    <g>
                        <g>
                            <path d="M12,2C8.14,2,5,5.14,5,9c0,5.25,7,13,7,13s7-7.75,7-13C19,5.14,15.86,2,12,2z M12,4c1.1,0,2,0.9,2,2c0,1.11-0.9,2-2,2 s-2-0.89-2-2C10,4.9,10.9,4,12,4z M12,14c-1.67,0-3.14-0.85-4-2.15c0.02-1.32,2.67-2.05,4-2.05s3.98,0.73,4,2.05 C15.14,13.15,13.67,14,12,14z"/>
                        </g>
                    </g>
                </g>
            </svg>`;
        this.btn.type = "button";
        const tooltip = "Open Streetview";
        // @ts-ignore
        this.btn.ariaLabel = tooltip;
        this.btn.title = tooltip;
        this.btn.id = "street-view-control";
        this.btn.classList.add("tooltip", "tooltip-left");
        this.btn.dataset.tooltip = tooltip;

        this.btn.onclick = () => {
            if (!this.marker) {
                this.marker = new maplibregl.Marker({ draggable: true });
                this.btn.classList.add("active");
                map.on("mousemove", e => {
                    if (this.marker) {
                        this.marker.setLngLat(e.lngLat).addTo(map);
                    }
                });
                map.on("click", () => {
                    this.onClicked();
                });
                const self = this;
                this.btn.addEventListener("blur", () => self.disable());
            } else {
                this.disable();
            }
        };

        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

        this.container.appendChild(this.btn);

        return this.container;
    }

    onRemove() {
        this.container.parentNode?.removeChild(this.container);
    }
}
