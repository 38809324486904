import { CreateElement } from "vue";
import { Component } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import * as d3 from "d3";
import { gigamapApiFactory } from "@/services";
import { getAuthToken } from "@/services";
import * as store from "@/store";
import { State } from "vuex-class";

@Component
export default class SLD extends tsx.Component<{}> {
    @State((s: store.State) => s.layers) layers: store.Layers;
    designId: string;
    cabinet_name: string;
    data_status: string;
    is_error: boolean = false;

    // FIXME what does this do?
    data() {
        return {
            cabinet_name: "",
            designId: "",
        };
    }

    async mounted() {
        var width = window.innerWidth;
        var height = window.innerHeight;
        const chartDiv = this.$refs.chart as HTMLDivElement;
        const searchParams = new URLSearchParams(location.search);
        // @ts-ignore
        this.cabinet_name = searchParams.get("cabinet_name");
        // @ts-ignore
        this.designId = searchParams.get("design_id");
        if (this.cabinet_name) {
            try {
                let data = await this.fetchData(this.cabinet_name, this.designId);
                if (data.graph) {
                    createChart(data);
                } else {
                    this.is_error = true;
                    this.data_status = data.title + ". " + data.detail;
                }
            } catch (error) {
                console.error("Error loading data:", error);
            }
        }

        function createChart(jsondata: any) {
            let edgeData = jsondata.edge_data;
            let selectionTracker: any = [];
            let rootNode = d3.hierarchy(jsondata.graph, d => d.children);

            var pathLinks = rootNode.links();
            var updatePathLinks;

            var nodeLinks = rootNode.descendants();
            var updateNodeLinks;

            var textLinks = rootNode.descendants();
            var updateTextLinks: any;

            const svg = d3
                .select(chartDiv)
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .call(
                    d3.zoom().on("zoom", function (event) {
                        svg.attr("transform", event.transform);
                    }),
                )
                .append("g")
                .attr("transform", "translate(50,50)");

            // @ts-ignore
            let g = svg.append("g").attr("transform", "translate(140,50)");

            const layout = d3.tree().size([width, height]).nodeSize([150, 500]);

            layout(rootNode);
            layout.separation(function (a, b) {
                return a.parent === b.parent ? 1 : 2;
            });

            function createLinks(data: any) {
                // @ts-ignore
                let group = g
                    .selectAll(".link")
                    .data(data, (d: any, i) => d.target.data.id)
                    .join(
                        function (enter) {
                            return (
                                enter
                                    .append("g")
                                    .attr("class", "link")
                                    .append("path")
                                    // @ts-ignore
                                    .attr("d", (d: any) => {
                                        // @ts-ignore
                                        return `M${d.source.y},${d.source.x} C${
                                            d.source.y + (d.target.y - d.source.y) / 2
                                        },${d.source.x} ${
                                            // @ts-ignore
                                            d.target.y - (d.target.y - d.source.y) / 2
                                            // @ts-ignore
                                        },${d.target.x} ${d.target.y},${d.target.x}`;
                                    })
                                    .attr("class", "link-path")
                                    .each(function (d: any) {
                                        for (var i = 0; i < edgeData.length; i++) {
                                            if (
                                                edgeData[i][0] == d.source.data.id &&
                                                edgeData[i][1] == d.target.data.id
                                            ) {
                                                if (edgeData[i][2].color != undefined) {
                                                    if (edgeData[i][2].type == "backhaul") {
                                                        d3.select(this)
                                                            //@ts-ignore
                                                            .style("stroke", d3.color(edgeData[i][2].color))
                                                            .style("stroke-dasharray", "3, 3");
                                                    } else {
                                                        d3.select(this)
                                                            //@ts-ignore
                                                            .style("stroke", d3.color(edgeData[i][2].color));
                                                    }
                                                } else {
                                                    //@ts-ignore
                                                    d3.select(this).style("stroke", d3.color("black"));
                                                }
                                            }
                                        }
                                    })
                                    .each(function (d: any) {
                                        for (var i = 0; i < edgeData.length; i++) {
                                            //@ts-ignore
                                            if (
                                                edgeData[i][0] == d.source.data.id &&
                                                edgeData[i][1] == d.target.data.id
                                            ) {
                                                //@ts-ignore
                                                if (edgeData[i][2].label != undefined) {
                                                    //@ts-ignore
                                                    d3.select(this.parentNode)
                                                        .append("text")
                                                        .text(edgeData[i][2].label)
                                                        // @ts-ignore
                                                        .attr("y", d => (d.source.x + d.target.x - 30) / 2)
                                                        // @ts-ignore
                                                        .attr("x", d => (d.source.y + d.target.y) / 2)
                                                        .attr("class", "link-label")
                                                        .attr("dy", "0.35em")
                                                        .attr("stroke", "white")
                                                        .attr("font-weight", "bold")
                                                        .attr("fill", "balck")
                                                        .attr("stroke-width", 0.25)
                                                        .attr("stroke-style", "solid");
                                                }
                                            }
                                        }
                                    })
                                    .style("stroke-width", 5)
                                    .style("fill", "none")
                            );
                        },
                        function (update) {
                            return update;
                        },
                        function (exit) {
                            // @ts-ignore
                            return exit.call(path =>
                                path
                                    .transition()
                                    .duration(300)
                                    .remove()
                                    .attr("d", (d: any) => {
                                        // @ts-ignore
                                        return `M${d.source.y},${d.source.x} C${
                                            d.source.y + (d.target.y - d.source.y) / 2
                                        },${d.source.x} ${
                                            // @ts-ignore
                                            d.target.y - (d.target.y - d.source.y) / 2
                                            // @ts-ignore
                                        },${d.target.x} ${d.target.y},${d.target.x}`;
                                    }),
                            );
                        },
                    );
            }
            createLinks(pathLinks);

            function createNode(data: any) {
                g.selectAll(".node")
                    .data(data, (d: any) => d.data.id)
                    .join(
                        function (enter) {
                            return enter
                                .append("g")
                                .attr("class", "node")
                                .append("text")
                                .attr("class", "node_text")
                                .attr("transform", (d: any) => `translate(${d.y},${d.x})`)
                                .each(function (d: any) {
                                    // @ts-ignore
                                    var lines = d.data.label.split("\n"); // Split text into lines based on newline character
                                    var textElement = d3.select(this);
                                    var lineSpace = 0;
                                    if (lines.length == 1) {
                                        lineSpace = 5;
                                    } else {
                                        lineSpace = -5 * (lines.length - 1);
                                    }
                                    for (var i = 0; i < lines.length; i++) {
                                        textElement
                                            .append("tspan")
                                            .attr("x", -10)
                                            .attr("dy", i ? "1em" : lineSpace) // Offset subsequent lines
                                            .attr("fill", "black")
                                            .attr("stroke", "black")
                                            .attr("stroke-width", 0.25)
                                            .attr("stroke-style", "solid")
                                            .text(lines[i]);
                                    }
                                })
                                .each(function (d: any) {
                                    // @ts-ignore
                                    const textWidth = this.getBBox().width;
                                    // @ts-ignore
                                    const textHeight = this.getBBox().height;
                                    // Append rectangle
                                    // @ts-ignore
                                    d3.select(this.parentNode)
                                        .insert("rect", "text")
                                        // @ts-ignore
                                        .attr("y", d.x - (textHeight + 10) / 2)
                                        .attr("x", d.y - 20)
                                        .attr("width", textWidth + 25)
                                        .attr("height", textHeight + 10)
                                        .attr("class", "node_rect")
                                        .attr("fill", "white")
                                        .attr("stroke-width", 3)
                                        .attr("stroke", (d: any) => {
                                            if (d.children == undefined) {
                                                return "red";
                                            }
                                            return "black";
                                        })
                                        .attr("id", d.data.id);
                                });
                        },
                        function (update) {
                            return update;
                        },
                        function (exit) {
                            return exit.call(path => path.transition().duration(300).remove());
                        },
                    )

                    .on("mouseover", function (d) {
                        // @ts-ignore
                        if (this.previousSibling.nodeName == "rect") {
                            // @ts-ignore
                            d3.select(this.previousSibling).attr("fill", "orange");
                        }
                    })
                    .on("mouseout", function (d) {
                        // @ts-ignore
                        if (this.previousSibling.nodeName == "rect") {
                            // @ts-ignore
                            d3.select(this.previousSibling).attr("fill", (d: any) => {
                                if (d.children == undefined) {
                                    return "white";
                                }
                                return "white";
                            });
                        }
                    })

                    .on("click", async function (d) {
                        // @ts-ignore
                        let selectionId: any;
                        // @ts-ignore
                        if (this.nodeName == "text") {
                            // @ts-ignore
                            selectionId = d3.select(this.previousSibling)["_groups"][0][0]["attributes"].id.value;
                        } else {
                            return;
                        }

                        //check if node is selected
                        //if it does, we need to send that data to update function
                        //and remove that object
                        // @ts-ignore
                        let checkSelectionExists = selectionTracker.filter(
                            // @ts-ignore
                            selection => selection.selectionId == selectionId,
                        );
                        if (checkSelectionExists[0] != undefined) {
                            //also remove this item from selection tracker
                            // @ts-ignore
                            selectionTracker = selectionTracker.filter(
                                (selection: any) => selection.selectionId != selectionId,
                            );

                            //handle path update
                            pathLinks = checkSelectionExists[0].previousPathData.concat(pathLinks);
                            createLinks(pathLinks);

                            //handle  node update
                            nodeLinks = checkSelectionExists[0].previousNodeData.concat(nodeLinks);
                            createNode(nodeLinks);

                            //handle text update
                            textLinks = checkSelectionExists[0].previousTextData.concat(textLinks);
                            updateText(textLinks);

                            return;
                        }

                        var clickedData: any = d3.select(this).datum();
                        var valueArray: any = await processedlinks(clickedData.links());

                        updatePathLinks = pathLinks.filter(function (item) {
                            return !valueArray.includes(item.target.data.id);
                        });

                        var selectedPathData = pathLinks.filter(function (item) {
                            return valueArray.includes(item.target.data.id);
                        });

                        console.log("clickedPathData" + selectedPathData.length);

                        updateNodeLinks = nodeLinks.filter(function (item) {
                            return !valueArray.includes(item.data.id);
                        });

                        var selectedNodeData = nodeLinks.filter(function (item) {
                            return valueArray.includes(item.data.id);
                        });

                        updateTextLinks = textLinks.filter(function (item: any) {
                            return !valueArray.includes(item.data.id);
                        });

                        var selectedTextData = textLinks.filter(function (item: any) {
                            return valueArray.includes(item.data.id);
                        });

                        selectionTracker.push({
                            selectionId: selectionId,
                            previousPathData: selectedPathData,
                            previousNodeData: selectedNodeData,
                            previousTextData: selectedTextData,
                        });

                        createLinks(updatePathLinks);
                        console.log("updatePathLinks f" + updatePathLinks.length);
                        createNode(updateNodeLinks);
                        updateText(updateTextLinks);
                        async function processedlinks(dlinks: any) {
                            var valueArray: any = [];

                            return new Promise((resolve, reject) => {
                                dlinks.forEach(async (element: any) => {
                                    valueArray.push(element.target.data.id);
                                });
                                resolve(valueArray);
                            });
                        }
                        pathLinks = updatePathLinks;
                        nodeLinks = updateNodeLinks;
                        textLinks = updateTextLinks;
                        console.log("pathLinks n" + pathLinks.length);
                    });
            }

            createNode(rootNode.descendants());

            function updateText(data: any) {
                g.selectAll(".text")
                    .data(data, (d: any) => d.data.asset_name)
                    .join(
                        function (enter) {
                            return enter
                                .append("text")
                                .attr("x", (d: any) => d.y)
                                .attr("y", (d: any) => d.x)
                                .attr("font-size", 0)
                                .text((d: any) => d.data.asset_name);
                        },
                        function (update) {
                            return update;
                        },
                        function (exit) {
                            return exit.call(text => text.transition().duration(300).remove());
                        },
                    )
                    .call(text => text.transition().duration(1000));
            }

            updateText(textLinks);
        }
    }

    render(h: CreateElement): JSX.Element {
        return (
            <div class="sld-outer-div">
                <div class="sld-title-div">Straight Line Diagram - {this.cabinet_name}</div>
                {this.is_error && (
                    <div class="status-bar" id="status-bar" style="padding: 10px; color: red;">
                        Error loading data - {this.data_status}
                    </div>
                )}
                <div class="sld-chart-div" id="chart" ref="chart"></div>
            </div>
        );
    }

    async fetchData(cabinet_name: string, designId: string) {
        try {
            const client = gigamapApiFactory(this.accessTokenFromStore());
            const response = await client.getStraightLineDiagramJsonFromAssetDb(cabinet_name, designId);
            if (response) {
                return response;
            } else {
                console.error("Error fetching SLD data:", response.statusText);
            }
        } catch (error) {
            return this.readStream(error.body);
        }
    }

    // @ts-ignore
    async readStream(stream) {
        const reader = stream.getReader();
        const decoder = new TextDecoder();

        let readResult = "";

        try {
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                readResult += decoder.decode(value, { stream: true });
            }
            readResult += decoder.decode();
            let resultJson = JSON.parse(readResult);
            return resultJson;
        } catch (error) {
            // @ts-ignore
            return `Stream reading error: ${error.message}`;
        }
    }

    accessTokenFromStore() {
        if (this.$store.state.authVersion === "AUTH_V2") {
            return this.$store.state.accessToken;
        } else {
            const [token] = getAuthToken();
            return token;
        }
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-67e9e8ec/sld.tsx" });