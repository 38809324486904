/**
 * WARNING: Do not modify
 *
 * API client generated by swagger-client-generator v1.2.0
 */

import { formatPathParams, formatParam, objectToUrlSearchParams } from "./common";


export interface GroupCN {
}

export interface OrganizationalUnitCN {
}

export interface Role {
    description: string;
    distinguishedNames?: string[];
    groups?: GroupCN[];
    organizationalUnits?: OrganizationalUnitCN[];
    role?: string;
}




export class AuthServiceClient {
    private baseUrl: string;
    private apiToken?: string;
    private globalFetchOptions: RequestInit;

    constructor(baseUrl: string, apiToken?: string, fetchOptions?: RequestInit) {
        this.baseUrl = baseUrl;
        this.apiToken = apiToken;
        this.globalFetchOptions = (fetchOptions === undefined) ? {} : fetchOptions;
    }

    get _headers(): Headers {
        let h = new Headers();
        if (this.apiToken) {
            h.append("Authorization", "Bearer " +  this.apiToken);
        }
        return h;
    }

    /**
     *  Check system availability
     */
    async getHealthcheck(): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/healthcheck", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Get the public key in JWKS format
     */
    async getJwks(): Promise<any> {
        let requestUrl = new URL(this.baseUrl + "/jwks", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Get the user's profile
     */
    async getUserProfile(username: string): Promise<Response> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/profile/{username}", {
                    "username": username,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Get the user's photograph
     */
    async getUserPhoto(username: string): Promise<Response> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/profile/{username}/photo", {
                    "username": username,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Get the public key for verifying tokens
     */
    async publicKey(): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/public-key", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Get the list of roles and their descriptions
     */
    async listRoles(): Promise<Role[]> {
        let requestUrl = new URL(this.baseUrl + "/roles", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Get the previously generated token from the token cookie
     */
    async getToken(): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/token", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Generate a new JWT token from the user's credentials
     */
    async createToken(username: string, password: string): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/token", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Content-Type", "application/x-www-form-urlencoded");
        fetchOptions["body"] = objectToUrlSearchParams({
            "username": formatParam(username, undefined),
            "password": formatParam(password, undefined),
        });
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  List all users with the given role
     */
    async getUsersWithRole(role_name: string): Promise<any> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/users/{role_name}", {
                    "role_name": role_name,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

}

