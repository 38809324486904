import { CreateElement } from "vue";
import { Component } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import {
    VIcon,
    VBtn,
    VTooltip,
    VTextField,
    VSpacer,
    VDialog,
    VList,
    VListItem,
    VCard,
    VCardTitle,
    VListItemAction,
    VListItemContent,
    VMenu,
} from "vuetify/lib";
import { State } from "vuex-class";
import SearchInput from "@/components/search-input";

import * as store from "@/store";
import { isMobileDisplay, MAP_CACHE } from "@/utils";
import { COMMUNITY_DOWNLOAD_STATUS } from "@/utils";
import Map from "@/views/map";

function toMegaBytes(bytes: number): string {
    return (bytes / (1024 * 1024)).toFixed(2) + "MB";
}

@Component({
    components: {
        VBtn,
        VIcon,
        VTooltip,
        VTextField,
        VSpacer,
        VDialog,
        VList,
        VListItem,
        VCard,
        VCardTitle,
        VListItemAction,
        VListItemContent,
        VMenu,
    },
})
export default class AppBar extends tsx.Component<{}> {
    @State((s: store.State) => s.accessToken !== undefined && s.accessToken !== "") isLoggedIn: boolean;
    @State((s: store.State) => s.layersControlVisible) layersControlVisible: boolean;
    @State((s: store.State) => s.legendVisible) legendVisible: boolean;

    mapsDialogVisible: boolean = false;
    cachedDesigns: string[] = [];
    storageEstimate?: StorageEstimate;
    communityDownloadStatus: { [key: string]: string };

    data() {
        return {
            mapsDialogVisible: false,
            cachedDesigns: [],
            storageEstimate: undefined,
            communityDownloadStatus: {},
        };
    }

    async getCachedDesigns(): Promise<string[]> {
        return (await caches.keys()).filter(
            key => key !== MAP_CACHE && !key.startsWith("workbox") && !key.startsWith("mapbox"),
        );
    }

    async getCachedDesignsDownloadStatus(): Promise<{ [key: string]: string }> {
        const communityDownloadStatus = localStorage.getItem(COMMUNITY_DOWNLOAD_STATUS);
        let communityDownloadStatusList: { [key: string]: string } = {};
        if (communityDownloadStatus != null) {
            communityDownloadStatusList = JSON.parse(communityDownloadStatus);
        }
        return communityDownloadStatusList;
    }

    renderButton(label: string, icon: string, active: boolean, action: () => void): JSX.Element {
        return (
            <v-tooltip
                bottom
                open-delay={400}
                scopedSlots={{
                    activator: ({ on }: any) => {
                        return (
                            <v-btn
                                id={`btn-${label.toLowerCase().replace(" ", "-")}`}
                                class={active ? "btn-active" : undefined}
                                icon
                                color="white"
                                onClick={action}
                                {...{ on }}
                            >
                                <v-icon medium>{icon}</v-icon>
                            </v-btn>
                        );
                    },
                }}
            >
                <span>{label}</span>
            </v-tooltip>
        );
    }

    async showOfflineMapsDialog() {
        this.mapsDialogVisible = !this.mapsDialogVisible;
        if (this.mapsDialogVisible) {
            if (navigator.storage.estimate !== undefined) {
                this.storageEstimate = await navigator.storage.estimate();
            }
            this.cachedDesigns = await this.getCachedDesigns();
            this.communityDownloadStatus = await this.getCachedDesignsDownloadStatus();
        }
    }

    async deleteCachedDesign(cacheName: string) {
        if (await caches.delete(cacheName)) {
            if (navigator.storage.estimate !== undefined) {
                this.storageEstimate = await navigator.storage.estimate();
            }
            this.cachedDesigns = await this.getCachedDesigns();
        }
    }

    actionMenu() {
        return (
            <v-menu
                scopedSlots={{
                    activator: ({ on }: any) => {
                        return (
                            <v-btn icon color="white" onClick={on.click}>
                                <v-icon medium>mdi-dots-vertical</v-icon>
                            </v-btn>
                        );
                    },
                }}
            >
                <v-list>
                    <v-list-item text onClick={this.toggleLayers}>
                        Layers
                    </v-list-item>
                    <v-list-item text onClick={this.toggleLegend}>
                        Legend
                    </v-list-item>
                    <v-list-item text onClick={this.showOfflineMapsDialog}>
                        Downloaded Maps
                    </v-list-item>
                </v-list>
            </v-menu>
        );
    }

    toggleLayers() {
        store.setLayerControlVisibility(this.$store, !this.layersControlVisible);
    }

    toggleLegend() {
        store.setLegendVisibility(this.$store, !this.legendVisible);
    }

    render(h: CreateElement): JSX.Element {
        const communityListItems = this.cachedDesigns.map(key => {
            return (
                <v-list-item>
                    <v-list-item-content>
                        {key}
                        {this.communityDownloadStatus[key] !== "Complete" ? (
                            <span style="color:red">
                                <i>{this.communityDownloadStatus[key]}</i>
                            </span>
                        ) : (
                            <span></span>
                        )}
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn icon onClick={() => this.deleteCachedDesign(key)}>
                            <v-icon medium>mdi-delete</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            );
        });
        const mapsDialog = (
            <v-dialog v-model={this.mapsDialogVisible} width="500px">
                <v-card>
                    <v-card-title>Downloaded Maps</v-card-title>
                    {this.storageEstimate && (
                        <div class="mx-6">Total data stored: {toMegaBytes(this.storageEstimate?.usage ?? 0)}</div>
                    )}
                    <v-list class="mx-3">{communityListItems}</v-list>
                </v-card>
            </v-dialog>
        );
        const inMapView =
            this.$router.getMatchedComponents(this.$route).findIndex(component => component === Map) !== -1;
        const barContent = isMobileDisplay()
            ? [this.actionMenu(), <SearchInput />]
            : [
                  this.renderButton("Layers", "mdi-format-list-bulleted", this.layersControlVisible, this.toggleLayers),
                  this.renderButton("Legend", "mdi-map-legend", this.legendVisible, this.toggleLegend),
                  <SearchInput />,
                  this.renderButton(
                      "Dowloaded Maps",
                      "mdi-folder-download",
                      this.mapsDialogVisible,
                      this.showOfflineMapsDialog,
                  ),
              ];
        return (
            <div class="actions">
                {inMapView && barContent}
                {this.mapsDialogVisible && mapsDialog}
            </div>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-2af38528/app-bar.tsx" });