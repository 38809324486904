
export function formatPathParams(formatString: string, parameters: { [key: string]: any }): string {
    let str = formatString;
    for (const key of Object.keys(parameters)) {
        str = str.replace(`{${key}}`, encodeURIComponent(String(parameters[key])));
    }
    return str;
}

export function formatParam(parameter: any, arraySeparator?: string): any {
    if (parameter instanceof Date) {
        return parameter.toISOString();
    } else if (parameter instanceof Array && arraySeparator) {
        return parameter.join(arraySeparator);
    } else {
        return parameter;
    }
}

export function objectToUrlSearchParams(data: { [key: string]: any }): URLSearchParams {
    const queryParams = new URLSearchParams();
    for (const key in data) {
        if (data[key] instanceof Array) {
            for (const item of data[key]) {
                queryParams.append(key, item);
            }
        } else if (typeof data[key] === "object") {
            for (const [k, v] of Object.entries(data[key])) {
                queryParams.append(k, v as any);
            }
        } else if (data[key] !== undefined) {
            queryParams.set(key, data[key]);
        }
    }
    return queryParams;
}
