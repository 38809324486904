import { Component } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { VCard, VForm, VDialog, VTextField, VSelect, VBtn, VCardTitle } from "vuetify/lib";
import * as store from "@/store";
import { CreateElement } from "vue";
import * as assetdb from "@/services/asset_db";
import { FeatureInfo, MyConfig } from "@/services/gigamap";
import { AssetProp, SelectItem } from "./asset-data-popup";
import { messages } from "@/services";

function isValidEnum(name: string): name is keyof typeof assetdb {
    return name in assetdb;
}

function enumToOptions<O extends object>(options: O): SelectItem[] {
    const selectOptions = [];
    const enumValues = Object.entries(options).map(([item]) => options[item as keyof O]);
    for (const val of enumValues) {
        if (typeof val === "string") {
            selectOptions.push({ text: val, value: val });
        }
    }
    return selectOptions;
}

@Component({ components: { VCard, VCardTitle, VDialog, VForm, VTextField, VSelect, VBtn } })
export default class EditFeatureDialog extends tsx.Component<{}> {
    visible: boolean;
    feature?: FeatureInfo;
    config?: MyConfig;
    source: string;

    data() {
        return {
            visible: false,
            feature: undefined,
            exportChangesControl: undefined,
            config: undefined,
            source: "",
        };
    }

    open(feature: FeatureInfo, config: MyConfig, source: string) {
        this.feature = feature;
        this.visible = true;
        this.config = config;
        this.source = source;
    }

    newFeatureEdit() {
        this.visible = false;
        if (this.feature) {
            store.addFeatureEdit(this.$store, this.feature);
            messages.$emit("new-feature-edit");
        }
    }

    getFeatureFields(): AssetProp[] {
        const layer = this.config?.layers.find(lyr => lyr.table === this.source);
        if (layer && layer.properties) {
            return layer.properties
                .filter(prop => prop.editable)
                .map(prop => {
                    let values: SelectItem[] | undefined;
                    if (prop.values) {
                        if (prop.values instanceof Array) {
                            values = prop.values.map(val => ({ text: val, value: val }));
                        } else if (isValidEnum(prop.values)) {
                            values = enumToOptions(assetdb[prop.values]);
                        }
                    }
                    const featureProps = this.feature
                        ? Object.fromEntries(this.feature.properties.map(p => [p.key, p.value]))
                        : {};
                    return {
                        name: prop.key,
                        label: prop.description,
                        type: values ? "select" : "string",
                        value: featureProps[prop.key],
                        values,
                    };
                });
        }
        return [];
    }

    render(h: CreateElement): JSX.Element {
        let form: any;
        if (this.feature) {
            const props = this.feature.properties;
            form = this.getFeatureFields().map(field => {
                const propIdx = props.findIndex(prop => prop.key === field.name) ?? -1;
                if (field.type === "string") {
                    return <v-text-field v-model={props[propIdx].value} label={field.label}></v-text-field>;
                } else if (field.type === "select") {
                    return (
                        <v-select v-model={props[propIdx].value} label={field.label} items={field.values}></v-select>
                    );
                } else if (field.type === "read") {
                    return <v-text-field value={field.value} label={field.label}></v-text-field>;
                }
            });
        }
        return (
            <v-dialog v-model={this.visible} max-width="500px">
                <v-card>
                    <v-card-title>Edit Feature</v-card-title>
                    <v-form
                        onSubmit={(event: Event) => {
                            event.preventDefault();
                            this.newFeatureEdit();
                        }}
                        class="py-3 px-6"
                    >
                        {form}
                        <v-btn onClick={() => this.newFeatureEdit()}>Save</v-btn>
                    </v-form>
                </v-card>
            </v-dialog>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-b30362be/edit-asset-popup.tsx" });