/**
 * WARNING: Do not modify
 *
 * API client generated by swagger-client-generator v1.2.0
 */

import { formatPathParams, formatParam, objectToUrlSearchParams } from "./common";


export enum AccessCabinetType {
    gen1 = "gen1",
    gen2 = "gen2",
}

export enum AreaAssetType {
    distribution_area = "distribution_area",
    HSEQ_risk_area = "HSEQ_risk_area",
}

export enum AssetCollectionType {
    unified_gen1_gen2 = "unified_gen1_gen2",
    unified_gen1_gen2_diff = "unified_gen1_gen2_diff",
}

export enum AssetType {
    access_cabinet = "access_cabinet",
    area_asset = "area_asset",
    atlas = "atlas",
    atlas_page = "atlas_page",
    building_aggregation_point = "building_aggregation_point",
    cable = "cable",
    chamber = "chamber",
    closure = "closure",
    drop_cabinet = "drop_cabinet",
    fanout_cable = "fanout_cable",
    gateway_cabinet = "gateway_cabinet",
    multi_duct = "multi_duct",
    note = "note",
    pole = "pole",
    port = "port",
    pot = "pot",
    sed = "sed",
    service_point = "service_point",
    splice = "splice",
    splitter = "splitter",
    trench = "trench",
    work_package = "work_package",
    workpoint = "workpoint",
    noi_order = "noi_order",
    swept_tee = "swept_tee",
}

export enum BranchStatus {
    open = "open",
    rejected = "rejected",
    merged = "merged",
}

export enum BuildStatus {
    hld = "hld",
    designed = "designed",
    asbuilt = "as-built",
}

export enum CabinetLiveStatus {
    Planning = "Planning",
    Build = "Build",
    Live__All = "Live - All",
}

export enum CabinetType {
    Generation_1_Access = "Generation 1 Access",
    Generation_2_Large_1_Keymile = "Generation 2 Large 1 Keymile",
    Generation_2_Large_2_Keymile = "Generation 2 Large 2 Keymile",
    Generation_2_Large_Transport = "Generation 2 Large Transport",
    Generation_2_Large_Hybrid = "Generation 2 Large Hybrid",
    Generation_2_Small_Access = "Generation 2 Small Access",
    Small_CO = "Small CO",
    Large_CO = "Large CO",
    MicroPON_CO_ST = "MicroPON CO ST",
    MicroPON_CO = "MicroPON CO",
}

export enum CableStart {
    to_endpoint_id = "to_endpoint_id",
    from_endpoint_id = "from_endpoint_id",
    auto_detect = "auto_detect",
}

export enum CableType {
    TKF = "TKF",
    OFCContecsa = "OFC/Contecsa",
    ULW = "ULW",
    MST_Tail = "MST Tail",
}

export enum ChamberType {
    BURIED = "BURIED",
    CW1 = "CW1",
    CW2 = "CW2",
    CW3 = "CW3",
    FW2 = "FW2",
    FW4 = "FW4",
    FW6 = "FW6",
    FW10 = "FW10",
    Openreach_JB23 = "Openreach JB23",
    Openreach_JB26 = "Openreach JB26",
    Openreach_JF2 = "Openreach JF2",
    Openreach_JF4 = "Openreach JF4",
    Openreach_JF6 = "Openreach JF6",
    Openreach_JF10 = "Openreach JF10",
    Openreach_JF11 = "Openreach JF11",
    Openreach_CW1 = "Openreach CW1",
    Openreach_CW2 = "Openreach CW2",
    Openreach_CW3 = "Openreach CW3",
    Openreach_NS = "Openreach NS",
}

export enum ClosureType {
    CSC100 = "CSC100",
    UFC = "UFC",
    FDN = "FDN",
    PASSIVE = "PASSIVE",
    TENIO = "TENIO",
    TENIO_C6 = "TENIO_C6",
    FIST_MSC_576 = "FIST_MSC_576",
    FIST_MSC_864 = "FIST_MSC_864",
    LARGE_CONNECTORISED_CLOSURE = "LARGE_CONNECTORISED_CLOSURE",
    MST_4 = "MST_4",
    MST_8 = "MST_8",
    MST_12 = "MST_12",
    OTE200_4 = "OTE200_4",
    OTE200_6 = "OTE200_6",
    OTE200_8 = "OTE200_8",
    OTE300_8 = "OTE300_8",
    OTE300_12 = "OTE300_12",
    UMJ = "UMJ",
    SMALL_CONNECTORISED_CLOSURE = "SMALL_CONNECTORISED_CLOSURE",
    WALLBOX_SMALL = "WALLBOX_SMALL",
    WALLBOX_MEDIUM = "WALLBOX_MEDIUM",
    WALLBOX_LARGE = "WALLBOX_LARGE",
}

export enum DropCabinetType {
    NORMAL = "NORMAL",
    FDN_SMALL = "FDN_SMALL",
    FDN_LARGE = "FDN_LARGE",
    FIST_EDSA = "FIST_EDSA",
}

export enum GatewayBackhaulType {
    Gigaclear_DWDM = "Gigaclear DWDM",
    Third_Party_Point_to_Point = "Third Party Point to Point",
    Third_Party_Point_to_Core = "Third Party Point to Core",
}

export enum GatewayCabinetType {
    _204 = "204",
    _533 = "533",
    _593 = "593",
    _594 = "594",
    Generation_2_Large_Transport = "Generation 2 Large Transport",
    Generation_2_Large_Hybrid = "Generation 2 Large Hybrid",
    Generation_2_Small_Gateway = "Generation 2 Small Gateway",
}

export enum HSEQRisk {
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW",
}

export enum HSEQRiskType {
    HAZARD__HIGH_VOLTAGE_ELECTRIC_OVERHEAD_11_kV = "HAZARD - HIGH VOLTAGE ELECTRIC OVERHEAD 11 kV",
    HAZARD__HIGH_VOLTAGE_ELECTRIC_OVERHEAD_33_kV = "HAZARD - HIGH VOLTAGE ELECTRIC OVERHEAD 33 kV",
    HAZARD__HIGH_VOLTAGE_ELECTRIC_OVERHEAD_132_kV = "HAZARD - HIGH VOLTAGE ELECTRIC OVERHEAD 132 kV",
    HAZARD__HIGH_VOLTAGE_ELECTRIC_OVERHEAD_275400_kV = "HAZARD - HIGH VOLTAGE ELECTRIC OVERHEAD 275/400 kV",
    HAZARD__HIGH_VOLTAGE_ELECTRIC_UNDERGROUND_11_kV = "HAZARD - HIGH VOLTAGE ELECTRIC UNDERGROUND 11 kV",
    HAZARD__HIGH_VOLTAGE_ELECTRIC_UNDERGROUND_33_kV = "HAZARD - HIGH VOLTAGE ELECTRIC UNDERGROUND 33 kV",
    HAZARD__LOW_VOLTAGE_OVERHEAD_UNINSULATED_240400_V = "HAZARD - LOW VOLTAGE OVERHEAD (UNINSULATED) (240/400 V)",
    HAZARD__ELECTRIC_SUBSTATION = "HAZARD - ELECTRIC SUBSTATION",
    HAZARD__HIGH_PRESSURE_GAS = "HAZARD - HIGH PRESSURE GAS",
    HAZARD__GAS_PUMPING_STATION = "HAZARD - GAS PUMPING STATION",
    HAZARD__HIGH_PRESSURE_WATER = "HAZARD - HIGH PRESSURE WATER",
    HAZARD__WATER_PUMPING_STATION = "HAZARD - WATER PUMPING STATION",
    HAZARD__OIL_PIPELINE = "HAZARD - OIL PIPELINE",
    HAZARD__FUEL_PIPELINE = "HAZARD - FUEL PIPELINE",
    HAZARD__WORKING_AT_HEIGHT = "HAZARD - WORKING AT HEIGHT",
    HAZARD__MOD_PIPELINESSYSTEMS = "HAZARD - MOD PIPELINES/SYSTEMS",
    HAZARD__CONFINED_SPACE_WORKING_below_ground = "HAZARD - CONFINED SPACE WORKING (below ground)",
    HAZARD__WORKING_NEAR_FAST_FLOWING_WATER_within_5_meters = "HAZARD - WORKING NEAR FAST FLOWING WATER (within 5 meters)",
    HAZARD__ASBESTOS_CONTAINING_MATERIALS_ACMs = "HAZARD - ASBESTOS CONTAINING MATERIALS (ACMs)",
    HAZARD__HIGH_SPEED_CARRIAGEWAYS_50_mph = "HAZARD - HIGH SPEED CARRIAGEWAYS (>50 mph)",
    HAZARD__RAILWAY_TRAMWAY_AND_LEVEL_CROSSINGS_50_meters = "HAZARD - RAILWAY, TRAMWAY AND LEVEL CROSSINGS (<50 meters)",
    HAZARD__CABINET_LOCATION_POSITIONING_PLACEMENT_MAINTENANCE = "HAZARD - CABINET LOCATION, POSITIONING, PLACEMENT, MAINTENANCE",
    HAZARD__HIGH_IMPACT_TRAFFIC_AREAS = "HAZARD - HIGH IMPACT TRAFFIC AREAS",
    HAZARD__POLE_ACCESS_EGRESS_AND_SPECIFIC_HAZARDS_1_meter = "HAZARD - POLE ACCESS, EGRESS AND SPECIFIC HAZARDS (<1 meter)",
    HAZARD__PETROL_STATION = "HAZARD - PETROL STATION",
    HAZARD__CARRIAGEWAYS_50_mph = "HAZARD - CARRIAGEWAYS (<50 mph)",
    HAZARD__CONFINED_SPACE_WORKING_above_ground = "HAZARD - CONFINED SPACE WORKING (above ground)",
    HAZARD__DAMAGEDDEFECTIVE_APPARATUS = "HAZARD - DAMAGED/DEFECTIVE APPARATUS",
    HAZARD__LOW_VOLTAGE_OVERHEAD_240400_V = "HAZARD - LOW VOLTAGE OVERHEAD (240/400 V)",
    HAZARD__LOW_VOLTAGE_UNDERGROUND = "HAZARD - LOW VOLTAGE UNDERGROUND",
    HAZARD__STRUCTURESUNDEGROUND_TUNNELSBASEMENTS = "HAZARD - STRUCTURES/UNDEGROUND TUNNELS/BASEMENTS",
    HAZARD__NATIONAL_HERITAGE_HISTORICAL_MONUMENTS = "HAZARD - NATIONAL HERITAGE/ HISTORICAL MONUMENTS",
    HAZARD__OPENREACH_PROTECTED_EQUIPMENT = "HAZARD - OPENREACH PROTECTED EQUIPMENT",
    HAZARD__BRIDGE_RAIL = "HAZARD - BRIDGE RAIL",
    HAZARD__BRIDGE_ROAD = "HAZARD - BRIDGE ROAD",
    HAZARD__OTHER = "HAZARD - OTHER",
    ATTENTION__AMBULANCEPOLICEFIRESTATION = "ATTENTION - AMBULANCE/POLICE/FIRESTATION",
    ATTENTION__HOSPITALMEDICAL_SURGERY = "ATTENTION - HOSPITAL/MEDICAL SURGERY",
    ATTENTION__PRISONHIGH_SECURITYMOD = "ATTENTION - PRISON/HIGH SECURITY/MOD",
    ATTENTION__EMBANKMENTRETAINING_WALL = "ATTENTION - EMBANKMENT/RETAINING WALL",
    ATTENTION__LISTEDPROTECTED_BUILDINGS = "ATTENTION - LISTED/PROTECTED BUILDINGS",
    ATTENTION__SCHOOL_COLLEGE_UNIVERSITY = "ATTENTION - SCHOOL/ COLLEGE/ UNIVERSITY",
    ATTENTION__SECTION_58_RESTRICTIONS = "ATTENTION - SECTION 58 RESTRICTIONS",
    ATTENTION__ACCEPTABLE_SITE_ACCESS_EGRESS = "ATTENTION - ACCEPTABLE SITE ACCESS/ EGRESS",
    ATTENTION__VANDALISM = "ATTENTION - VANDALISM",
    ATTENTION__OTHER = "ATTENTION - OTHER",
    ENVIRONMENTAL__WATERWAY_WATER_COURSE_RIVER_CANAL = "ENVIRONMENTAL - WATERWAY/ WATER COURSE/ RIVER/ CANAL",
    ENVIRONMENTAL__HORTICULTURAL_CONTROLLED_WASTE_JAPANESE_KNOT_ETC = "ENVIRONMENTAL - HORTICULTURAL CONTROLLED WASTE/ JAPANESE KNOT, ETC",
    ENVIRONMENTAL__NJUG_TREES_CANOPIES_ROOTS_VEGETATION = "ENVIRONMENTAL - NJUG TREES/ CANOPIES, ROOTS, VEGETATION",
    ENVIRONMENTAL__NOISE_POLLUTION = "ENVIRONMENTAL - NOISE POLLUTION",
    ENVIRONMENTAL__ARCHAEOLOGY = "ENVIRONMENTAL - ARCHAEOLOGY",
    ENVIRONMENTAL__THIRD_PARTY_WASTE_STORAGE_DISPOSAL = "ENVIRONMENTAL - THIRD PARTY WASTE STORAGE/ DISPOSAL",
    ENVIRONMENTAL__TREES_HEDGES_SHRUBBERY = "ENVIRONMENTAL - TREES/ HEDGES/ SHRUBBERY",
    ENVIRONMENTAL__WORKING_HOURS_RESTRICTIONS = "ENVIRONMENTAL - WORKING HOURS RESTRICTIONS",
    ENVIRONMENTAL__CONTAMINATED_GROUND = "ENVIRONMENTAL - CONTAMINATED GROUND",
    ENVIRONMENTAL__PROTECTED_SPECIES = "ENVIRONMENTAL - PROTECTED SPECIES",
    ENVIRONMENTAL_ANIMALSLIVESTOCK = "ENVIRONMENTAL- ANIMALS/LIVESTOCK",
    ENVIRONMENTAL__OTHER = "ENVIRONMENTAL - OTHER",
}

export enum InstallType {
    standard = "standard",
    nonstandard = "non-standard",
    mdu = "mdu",
    special = "special",
    PIA_UG = "PIA UG",
    PIA_OH = "PIA OH",
    complex_install = "complex install",
}

export enum MultiductType {
    DROP1 = "DROP-1",
    DUCTS4 = "DUCTS-4",
    DUCTS6 = "DUCTS-6",
    _54mm_Conventional_Duct = "54mm Conventional Duct",
    _96mm_Conventional_Duct = "96mm Conventional Duct",
    _100mm_Duct = "100mm Duct",
    Openreach_Duct_Access_25mm = "Openreach Duct Access 25mm",
    _1612mm_Subduct = "16/12mm Subduct",
}

export enum Owner {
    Openreach = "Openreach",
    WPD = "WPD",
    UKPN = "UKPN",
    SSE = "SSE",
    Gigaclear = "Gigaclear",
}

export enum PotType {
    Pot = "Pot",
    Wallbox = "Wallbox",
    Unknown = "Unknown",
}

export enum SplicingMethod {
    tubebased = "tube-based",
    fibrebased = "fibre-based",
}

export enum SplitterSize {
    _12 = "1:2",
    _14 = "1:4",
    _18 = "1:8",
    _116 = "1:16",
    _132 = "1:32",
}

export enum SweptTeeOrientation {
    right = "right",
    left = "left",
}

export enum TrenchType {
    UNDERGROUND = "UNDERGROUND",
    OVERHEAD = "OVERHEAD",
}

export interface AccessCabinet {
    friendly_name: string;
    geometry: string;
    id: string;
    name: string;
    offset: [number, number];
    rotation: number;
    type: AccessCabinetType;
    build_status?: BuildStatus;
    cabinet_type: CabinetType;
    chamber_type?: ChamberType;
    changeset_id?: number;
    closures_fanouts?: { [key: string]: FanoutCable[] };
    design_id?: string;
    extra_fibres?: number;
    owner?: Owner;
    permanent_id?: string;
    region?: string;
    splicing_method?: SplicingMethod;
    third_party_id?: string;
}

export interface AccessCabinetSummary {
    friendly_name: string;
    id: string;
    name: string;
    permanent_id: string;
    bbox?: [number, number, number, number];
    region?: string;
}

export interface AreaAsset {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    name: string;
    type: AreaAssetType;
    asset_data?: any;
    changeset_id?: number;
    feeder_node_id?: string;
    permanent_id?: string;
}

export interface AssetChanges {
    access_cabinets?: [AccessCabinet, AccessCabinet][];
    area_assets?: [AreaAsset, AreaAsset][];
    atlas_pages?: [AtlasPage, AtlasPage][];
    atlases?: [Atlas, Atlas][];
    building_aggregation_points?: [BuildingAggregationPoint, BuildingAggregationPoint][];
    cables?: [Cable, Cable][];
    chambers?: [Chamber, Chamber][];
    closures?: [Closure, Closure][];
    drop_cabinets?: [DropCabinet, DropCabinet][];
    gateway_cabinets?: [GatewayCabinet, GatewayCabinet][];
    multi_ducts?: [Multiduct, Multiduct][];
    noi_orders?: [NOIOrder, NOIOrder][];
    notes?: [Note, Note][];
    poles?: [Pole, Pole][];
    ports?: [Port, Port][];
    pots?: [Pot, Pot][];
    seds?: [SED, SED][];
    service_points?: [ServicePoint, ServicePoint][];
    splices?: [Splice, Splice][];
    splitters?: [Splitter, Splitter][];
    swept_tees?: [SweptTee, SweptTee][];
    trenches?: [Trench, Trench][];
    work_packages?: [WorkPackage, WorkPackage][];
    workpoints?: [Workpoint, Workpoint][];
}

export interface AssetCollection {
    access_cabinets?: AccessCabinet[];
    area_assets?: AreaAsset[];
    atlas_pages?: AtlasPage[];
    atlases?: Atlas[];
    building_aggregation_points?: BuildingAggregationPoint[];
    cables?: Cable[];
    chambers?: Chamber[];
    closures?: Closure[];
    drop_cabinets?: DropCabinet[];
    gateway_cabinets?: GatewayCabinet[];
    multi_ducts?: Multiduct[];
    noi_orders?: NOIOrder[];
    notes?: Note[];
    poles?: Pole[];
    ports?: Port[];
    pots?: Pot[];
    seds?: SED[];
    service_points?: ServicePoint[];
    splices?: Splice[];
    splitters?: Splitter[];
    swept_tees?: SweptTee[];
    trenches?: Trench[];
    work_packages?: WorkPackage[];
    workpoints?: Workpoint[];
}

export interface AssetCollectionMetadata {
    schema_version: number;
    type: AssetCollectionType;
    cabinet_areas_semantic_versions?: { [key: string]: DesignVersion };
    cabinet_areas_versions: { [key: string]: number };
    db_version?: number;
    ddp_metadata?: any;
    last_update_details?: LastUpdateDetails;
    requested_at: Date | string;
    server_query: string;
    server_version: string;
}

export interface AssetCollectionResponse {
    assets: AssetCollection;
    metadata: AssetCollectionMetadata;
}

export interface AssetReference {
    id: string;
    type: AssetType;
}

export interface AssetUUIDs {
    access_cabinets?: string[];
    area_assets?: string[];
    atlas_pages?: string[];
    atlases?: string[];
    building_aggregation_points?: string[];
    cables?: string[];
    chambers?: string[];
    closures?: string[];
    drop_cabinets?: string[];
    gateway_cabinets?: string[];
    multi_ducts?: string[];
    noi_orders?: string[];
    notes?: string[];
    poles?: string[];
    ports?: string[];
    pots?: string[];
    seds?: string[];
    service_points?: string[];
    splices?: string[];
    splitters?: string[];
    swept_tees?: string[];
    trenches?: string[];
    work_packages?: string[];
    workpoints?: string[];
}

export interface Atlas {
    cabinet_areas: string[];
    highest_page_number: number;
    id: string;
    name: string;
    origin_point: string;
    pages_overlap: number;
    scale: number;
    changeset_id?: number;
    permanent_id?: string;
}

export interface AtlasPage {
    atlas_id: string;
    cabinet_areas: string[];
    geometry: string;
    id: string;
    page_number: number;
    changeset_id?: number;
    permanent_id?: string;
}

export interface Branch {
    name: string;
    author?: string;
    cabinet_areas?: string[];
    data?: any;
    date_created?: Date | string;
    date_merged?: Date | string;
    date_modified?: Date | string;
    id?: number;
    merged_by?: string;
    message?: string;
    regions?: string[];
    status?: BranchStatus;
}

export interface BranchArchived {
    branch_id: number;
    cabinet_areas: string[];
    date_modified: Date | string;
    id: number;
    message: string;
    name: string;
    data?: any;
    regions?: string[];
}

export interface BranchConflictResolutionResponse {
    errors: { [key: string]: any };
    errors_resolved_count: number;
    new_branch?: Branch;
}

export interface BuildClosureResponse {
    is_built: boolean;
    permanent_id: string;
    property_service_ids: string[];
    time_of_update: Date | string;
    build_date?: Date | string;
    child_pots?: BuildPotResponse[];
    name?: string;
}

export interface BuildPotResponse {
    is_built: boolean;
    permanent_id: string;
    property_service_ids: string[];
    time_of_update: Date | string;
    build_date?: Date | string;
    name?: string;
}

export interface BuildUpdatesSinceTimestamp {
    build_closures: BuildClosureResponse[];
    build_pots: BuildPotResponse[];
}

export interface BuildingAggregationPoint {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    internal: boolean;
    name: string;
    rotation: number;
    build_status?: BuildStatus;
    changeset_id?: number;
    feeder_node_id?: string;
    owner?: Owner;
    permanent_id?: string;
    placement?: string;
    third_party_id?: string;
}

export interface CabinetAreaSummary {
    design_id: string;
    friendly_name: string;
    latest_cabinet_id: string;
    name: string;
    permanent_cabinet_id: string;
    bbox?: [number, number, number, number];
    last_changeset?: number;
    last_updated?: Date | string;
    live_status?: CabinetLiveStatus;
    region?: string;
    semantic_version?: DesignVersion;
    version?: number;
}

export interface CabinetLiveEvent {
    asset_id: string;
    data: CabinetLiveEventData;
}

export interface CabinetLiveEventData {
    metadata: EventMetadata;
    payload: any;
}

export interface CabinetLiveStatusIsBuild {
}

export interface CabinetLiveStatusIsLive {
}

export interface CabinetLiveStatusIsPlanning {
}

export interface CabinetLiveStatusUpdate {
    cabinet_permanent_id: string;
    last_modified_timestamp: Date | string;
    status: CabinetLiveStatus;
    updated_by: string;
}

export interface Cable {
    assigned_closures: { [key: string]: string };
    cabinet_areas: string[];
    from_endpoint_id: AssetReference;
    geometry: string;
    id: string;
    name: string;
    print_offset: number;
    size: number;
    to_endpoint_id: AssetReference;
    type: CableType;
    assigned_closures_list: string[];
    build_status?: BuildStatus;
    cable_start?: CableStart;
    changeset_id?: number;
    permanent_id?: string;
}

export interface Chamber {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    name: string;
    rotation: number;
    type: ChamberType;
    build_status?: BuildStatus;
    changeset_id?: number;
    feeder_node: boolean;
    feeder_node_id?: string;
    owner?: Owner;
    permanent_id?: string;
    placement?: string;
    third_party_id?: string;
}

export interface Changeset {
    metadata: ChangesetMetadata;
    deletes?: string[];
    id?: number;
    inserts?: AssetCollection;
    updates?: AssetCollection;
}

export interface ChangesetCommit {
    metadata: ChangesetMetadata;
    asset_id_map?: { [key: string]: string };
    deletes?: string[];
    id?: number;
    inserts?: AssetCollection;
    updates?: AssetChanges;
}

export interface ChangesetConflict {
    as_built_conflicts?: string[];
    deletes?: string[];
    deletion_conflicts?: [string, string, string][];
    duplicate_uuids?: string[];
    inserts?: AssetUUIDs;
    message?: string;
    missing_endpoint_ids?: string[];
    updates?: AssetUUIDs;
    work_package_conflict?: WorkPackageConflict;
}

export interface ChangesetMetadata {
    message: string;
    author?: string;
    cabinet_areas?: string[];
    last_update_details?: LastUpdateDetails;
    merged_by?: string;
    regions?: string[];
    timestamp?: Date | string;
}

export interface Closure {
    cabinet_areas: string[];
    endpoint_id: AssetReference;
    id: string;
    name: string;
    type: ClosureType;
    backhaul?: number;
    build_status?: BuildStatus;
    changeset_id?: number;
    feeder_node_id?: string;
    is_feeder_node: boolean;
    max_properties?: number;
    permanent_id?: string;
    reserved_fibres?: number;
    spare_fibres?: number;
}

export interface CloudEvent {
    id: string;
    specversion: string;
}

export interface DesignMetadata {
    id: string;
    bbox?: [number, number, number, number];
    last_changeset?: number;
    last_updated?: Date | string;
    semantic_version?: DesignVersion;
}

export interface DesignSummary {
    access_cabinets: AccessCabinetSummary[];
    design_metadata: DesignMetadata;
    feeder_nodes: FeederNodeSummary[];
}

export interface DesignVersion {
    major: number;
    minor: number;
    patch: number;
}

export interface DesignVersionDetails {
    design_id: string;
    import_id: number;
    skipped: boolean;
    version: number;
    changeset_id?: number;
    import_source?: string;
    import_timestamp?: Date | string;
    last_update_details?: LastUpdateDetails;
    minor_version?: number;
    patch_version?: number;
    reason_for_skipping?: string;
}

export interface DesignVersionResponse {
    design_versions_details: DesignVersionDetails[];
}

export interface DropCabinet {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    name: string;
    offset: [number, number];
    rotation: number;
    type: DropCabinetType;
    build_status?: BuildStatus;
    changeset_id?: number;
    feeder_node_id?: string;
    owner?: Owner;
    permanent_id?: string;
    third_party_id?: string;
}

export interface EventMetadata {
    datetime: Date | string;
    email: string;
    roles: string[];
}

export interface FanoutCable {
    id: string;
    name: string;
    permanent_id?: string;
}

export interface FeederNodeSummary {
    id: string;
    name: string;
    permanent_id: string;
}

export interface GatewayCabinet {
    backhaul_type: GatewayBackhaulType;
    cabinet_areas: string[];
    cabinet_type: GatewayCabinetType;
    friendly_name: string;
    geometry: string;
    id: string;
    name: string;
    offset: [number, number];
    rotation: number;
    build_status?: BuildStatus;
    changeset_id?: number;
    owner?: Owner;
    permanent_id?: string;
    third_party_id?: string;
}

export interface HSEQData {
    risk_type: HSEQRiskType;
    controls?: string;
    description?: string;
    risk?: HSEQRisk;
}

export interface IsBuiltStatusUpdate {
    is_built: boolean;
    permanent_id: string;
    build_date?: Date | string;
}

export interface LastUpdateDetails {
    last_updated_by?: string;
}

export interface Multiduct {
    backhaul: number[];
    cabinet_areas: string[];
    cables: { [key: string]: string };
    from_endpoint_id: AssetReference;
    geometry: string;
    id: string;
    name: string;
    print_offset: number;
    to_endpoint_id: AssetReference;
    type: MultiductType;
    build_status?: BuildStatus;
    changeset_id?: number;
    owner?: Owner;
    permanent_id?: string;
    third_party_id?: string;
}

export interface NOIOrder {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    name: string;
    changeset_id?: number;
    noi_number?: string;
    permanent_id?: string;
    pia_tracker_id?: string;
}

export interface Note {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    name: string;
    text: string;
    changeset_id?: number;
    linked_asset_ids?: string[];
    permanent_id?: string;
}

export interface Pole {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    name: string;
    build_status?: BuildStatus;
    changeset_id?: number;
    feeder_node_id?: string;
    owner?: Owner;
    permanent_id?: string;
    third_party_id?: string;
}

export interface Port {
    cabinet_areas: string[];
    closure_id: string;
    from_cable_id: string;
    from_fibre: number;
    id: string;
    name: string;
    to_port_index: number;
    build_status?: BuildStatus;
    changeset_id?: number;
    permanent_id?: string;
    splitter_id?: string;
}

export interface Pot {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    name: string;
    type: PotType;
    build_status?: BuildStatus;
    changeset_id?: number;
    closure_port?: string;
    feeder_node_id?: string;
    owner?: Owner;
    permanent_id?: string;
    third_party_id?: string;
}

export interface Problem {
    detail: string;
    title: string;
    type: string;
}

export interface Region {
    geometry: string;
    name: string;
}

export interface RegionNameResponse {
    regions: string[];
}

export interface RegionResponse {
    regions: Region[];
}

export interface SED {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    name: string;
    text: string;
    category?: string;
    changeset_id?: number;
    impact?: string;
    location?: string;
    permanent_id?: string;
    third_party?: string;
}

export interface ServicePoint {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    assigned_closure_id?: string;
    changeset_id?: number;
    contract_name?: string;
    demarc_endpoint_id?: string;
    external_id?: string;
    funded?: boolean;
    install_name?: string;
    install_type?: InstallType;
    permanent_id?: string;
    property_service_id?: string;
}

export interface Splice {
    cabinet_areas: string[];
    closure_id: string;
    from_cable_id: string;
    from_fibre: number;
    id: string;
    name: string;
    build_status?: BuildStatus;
    changeset_id?: number;
    permanent_id?: string;
    splitter_id?: string;
    to_cable_id?: string;
    to_fibre?: number;
}

export interface SplicingAssetsInCabinetClosure {
    assets: AssetCollection;
    cabinet_type: CabinetType;
}

export interface SplicingAssetsInClosure {
    assets: AssetCollection;
}

export interface Splitter {
    cabinet_areas: string[];
    closure_id: string;
    id: string;
    name: string;
    size: SplitterSize;
    build_status?: BuildStatus;
    changeset_id?: number;
    permanent_id?: string;
}

export interface Subscriber {
    url: string;
}

export interface SweptTee {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    name: string;
    orientation: SweptTeeOrientation;
    rotation: number;
    build_status?: BuildStatus;
    changeset_id?: number;
    feeder_node_id?: string;
    permanent_id?: string;
}

export interface Trench {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    backhaul?: boolean;
    build_status?: BuildStatus;
    changeset_id?: number;
    owner?: Owner;
    permanent_id?: string;
    third_party_id?: string;
    type: TrenchType;
}

export interface WorkPackage {
    cabinet_areas: string[];
    id: string;
    name: string;
    assets?: string[];
    changeset_id?: number;
    date_created?: Date | string;
    permanent_id?: string;
}

export interface WorkPackageConflict {
    name_conflicts?: [string, string, string][];
    nested_conflicts?: [any, any][];
}

export interface Workpoint {
    cabinet_areas: string[];
    geometry: string;
    id: string;
    name: string;
    text: string;
    changeset_id?: number;
    permanent_id?: string;
}




export class AssetDatabaseClient {
    private baseUrl: string;
    private apiToken?: string;
    private globalFetchOptions: RequestInit;

    constructor(baseUrl: string, apiToken?: string, fetchOptions?: RequestInit) {
        this.baseUrl = baseUrl;
        this.apiToken = apiToken;
        this.globalFetchOptions = (fetchOptions === undefined) ? {} : fetchOptions;
    }

    get _headers(): Headers {
        let h = new Headers();
        if (this.apiToken) {
            h.append("Authorization", "Bearer " +  this.apiToken);
        }
        return h;
    }

    /**
     *  Retrieve asset names given a list of their ids
     */
    async getAssetNamesByIds(asset_ids: string[]): Promise<any> {
        let requestUrl = new URL(this.baseUrl + "/asset_names/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Content-Type", "application/json");
        fetchOptions["body"] = JSON.stringify(asset_ids);
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Find specific assets for given type, field and value
     */
    async getAssetsByTypeAndValue(asset_type: AssetType, key: string, value: string, valid_at?: Date | string): Promise<AssetCollection> {
        let requestUrl = new URL(this.baseUrl + "/assets-by-value/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "asset_type": formatParam(asset_type, undefined),
            "key": formatParam(key, undefined),
            "value": formatParam(value, undefined),
            "valid_at": formatParam(valid_at, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Retrieve an asset collection
     */
    async getAssets(bbox?: [number, number, number, number], asset_types?: AssetType[], cabinet_name?: string, design_id?: string, permanent_ids?: string[], work_package_id?: string, valid_at?: Date | string, feeder_node_id?: string, design_version?: string): Promise<AssetCollectionResponse> {
        let requestUrl = new URL(this.baseUrl + "/assets/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "bbox": formatParam(bbox, undefined),
            "asset_types": formatParam(asset_types, undefined),
            "cabinet_name": formatParam(cabinet_name, undefined),
            "design_id": formatParam(design_id, undefined),
            "permanent_ids": formatParam(permanent_ids, ","),
            "work_package_id": formatParam(work_package_id, undefined),
            "valid_at": formatParam(valid_at, undefined),
            "feeder_node_id": formatParam(feeder_node_id, undefined),
            "design_version": formatParam(design_version, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Retrieve assets given a list of their id's
     */
    async getAssetsByIds(asset_ids: string[], valid_at?: Date | string): Promise<AssetCollectionResponse> {
        let requestUrl = new URL(this.baseUrl + "/assets/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Content-Type", "application/json");
        fetchOptions["body"] = JSON.stringify(asset_ids);
        requestUrl.search = objectToUrlSearchParams({
            "valid_at": formatParam(valid_at, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  List branches
     */
    async listBranches(status?: string, cabinet_name?: string, created_from?: Date | string): Promise<Branch[]> {
        let requestUrl = new URL(this.baseUrl + "/branches/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "status": formatParam(status, undefined),
            "cabinet_name": formatParam(cabinet_name, undefined),
            "created_from": formatParam(created_from, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Create a branch
     */
    async createBranch(branch: Branch): Promise<Branch> {
        let requestUrl = new URL(this.baseUrl + "/branches/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Content-Type", "application/json");
        fetchOptions["body"] = JSON.stringify(branch);
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Check potential conflicts on branch merge. changeset must be of Changeset type.
     */
    async checkMergeConflicts(branch_id?: number, override_as_built_assets?: boolean, changeset?: any): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/branches/check-merge-conflicts/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Content-Type", "application/json");
        fetchOptions["body"] = JSON.stringify(changeset);
        requestUrl.search = objectToUrlSearchParams({
            "branch_id": formatParam(branch_id, undefined),
            "override_as_built_assets": formatParam(override_as_built_assets, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Delete (reject) a branch
     */
    async deleteBranch(id: number): Promise<Branch> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/branches/{id}/", {
                    "id": id,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "DELETE", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Retrieve a single branch
     */
    async getBranch(id: number): Promise<Branch> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/branches/{id}/", {
                    "id": id,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Update a branch
     */
    async updateBranch(id: number, updated_branch: Branch): Promise<Branch> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/branches/{id}/", {
                    "id": id,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "PUT", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Content-Type", "application/json");
        fetchOptions["body"] = JSON.stringify(updated_branch);
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Retrieve history for a branch
     */
    async getBranchHistory(id: number, include_branch_data?: boolean): Promise<BranchArchived[]> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/branches/{id}/history/", {
                    "id": id,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "include_branch_data": formatParam(include_branch_data, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Merge a branch into the Asset DB
     */
    async mergeBranch(id: number, override_as_built_assets?: boolean): Promise<ChangesetCommit> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/branches/{id}/merge/", {
                    "id": id,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "override_as_built_assets": formatParam(override_as_built_assets, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Re-open a single rejected branch
     */
    async openBranch(id: number): Promise<Branch> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/branches/{id}/re-open/", {
                    "id": id,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Rename an existing branch
     */
    async renameBranch(id: number, new_branch_name: string): Promise<Branch> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/branches/{id}/rename/", {
                    "id": id,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "new_branch_name": formatParam(new_branch_name, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Generate a straight line diagram json for a cabinet area
     */
    async straightLineDiagramJson(cabinet_name: string, valid_at?: Date | string): Promise<any> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/cabinet-area/{cabinet_name}/straight-line-diagram-json/", {
                    "cabinet_name": cabinet_name,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "valid_at": formatParam(valid_at, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Generate a straight line diagram for a cabinet area
     */
    async straightLineDiagram_pdf(cabinet_name: string, image_format?: string, valid_at?: Date | string): Promise<Response> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/cabinet-area/{cabinet_name}/straight-line-diagram/", {
                    "cabinet_name": cabinet_name,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Accept", "application/pdf");
        requestUrl.search = objectToUrlSearchParams({
            "image_format": formatParam(image_format, undefined),
            "valid_at": formatParam(valid_at, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Generate a straight line diagram for a cabinet area
     */
    async straightLineDiagram_png(cabinet_name: string, image_format?: string, valid_at?: Date | string): Promise<Response> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/cabinet-area/{cabinet_name}/straight-line-diagram/", {
                    "cabinet_name": cabinet_name,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Accept", "image/png");
        requestUrl.search = objectToUrlSearchParams({
            "image_format": formatParam(image_format, undefined),
            "valid_at": formatParam(valid_at, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Generate a straight line diagram for a cabinet area
     */
    async straightLineDiagram_svg(cabinet_name: string, image_format?: string, valid_at?: Date | string): Promise<Response> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/cabinet-area/{cabinet_name}/straight-line-diagram/", {
                    "cabinet_name": cabinet_name,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Accept", "image/svg");
        requestUrl.search = objectToUrlSearchParams({
            "image_format": formatParam(image_format, undefined),
            "valid_at": formatParam(valid_at, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Use with EXTREME care and making sure all the affected parties (DEV, Geomatics, Netadmin) has been notified. Only authorized people may be allowed to perform this action.
     */
    async commitRenameCabinet(old_cabinet_name: string, new_cabinet_name: string, new_cabinet_friendly_name?: string): Promise<BranchConflictResolutionResponse> {
        let requestUrl = new URL(this.baseUrl + "/cabinet-live/rename-cabinet/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "PUT", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "old_cabinet_name": formatParam(old_cabinet_name, undefined),
            "new_cabinet_name": formatParam(new_cabinet_name, undefined),
            "new_cabinet_friendly_name": formatParam(new_cabinet_friendly_name, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Update a cabinet's live status. If this update is not reflected in Salesforce within 15 minutes, please contact DevSupport
     */
    async putCabLive(cabinet_name: string, cabinet_live_status: CabinetLiveStatus): Promise<CabinetLiveStatusUpdate> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/cabinet-live/{cabinet_name}/", {
                    "cabinet_name": cabinet_name,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "PUT", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "cabinet_live_status": formatParam(cabinet_live_status, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  List of cabinet live status revisions
     */
    async getCabLiveHistory(cabinet_name: string): Promise<CabinetLiveStatusUpdate[]> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/cabinet-live/{cabinet_name}/history/", {
                    "cabinet_name": cabinet_name,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  List all cabinet areas
     */
    async getCabinetAreas(include_version_details?: boolean, include_bbox?: boolean, include_region?: boolean, include_live_status?: boolean): Promise<CabinetAreaSummary[]> {
        let requestUrl = new URL(this.baseUrl + "/cabinet_areas/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "include_version_details": formatParam(include_version_details, undefined),
            "include_bbox": formatParam(include_bbox, undefined),
            "include_region": formatParam(include_region, undefined),
            "include_live_status": formatParam(include_live_status, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Get a cabinet area by name
     */
    async getCabinetAreaByName(cabinet_name: string, include_version_details?: boolean, include_bbox?: boolean, include_region?: boolean, include_live_status?: boolean): Promise<CabinetAreaSummary> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/cabinet_areas/{cabinet_name}/", {
                    "cabinet_name": cabinet_name,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "include_version_details": formatParam(include_version_details, undefined),
            "include_bbox": formatParam(include_bbox, undefined),
            "include_region": formatParam(include_region, undefined),
            "include_live_status": formatParam(include_live_status, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Get the path between two assets.
     */
    async getCablePathBetweenAssets(design_id: string, target_asset_id: string, root_node_id?: string): Promise<string[]> {
        let requestUrl = new URL(this.baseUrl + "/cable-path-between-assets/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "design_id": formatParam(design_id, undefined),
            "target_asset_id": formatParam(target_asset_id, undefined),
            "root_node_id": formatParam(root_node_id, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  List changesets
     */
    async listChangesets(cabinet_name?: string, design_id?: string): Promise<ChangesetCommit[]> {
        let requestUrl = new URL(this.baseUrl + "/changesets/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "cabinet_name": formatParam(cabinet_name, undefined),
            "design_id": formatParam(design_id, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Submit a new changeset
     */
    async commitChangeset(changeset: Changeset, override_as_built_assets?: boolean): Promise<ChangesetCommit> {
        let requestUrl = new URL(this.baseUrl + "/changesets/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Content-Type", "application/json");
        fetchOptions["body"] = JSON.stringify(changeset);
        requestUrl.search = objectToUrlSearchParams({
            "override_as_built_assets": formatParam(override_as_built_assets, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Retrieve a single changeset
     */
    async getChangeset(id: number, include_asset_changes?: boolean, asset_types?: AssetType[]): Promise<ChangesetCommit> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/changesets/{id}/", {
                    "id": id,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "include_asset_changes": formatParam(include_asset_changes, undefined),
            "asset_types": formatParam(asset_types, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Revert a changeset
     */
    async revertChangeset(id: number, override_as_built_assets?: boolean): Promise<ChangesetCommit> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/changesets/{id}/revert_changeset/", {
                    "id": id,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "override_as_built_assets": formatParam(override_as_built_assets, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  List changesets / versions for a given cabinet area
     */
    async listChangesetsByDesignVersion(cabinet_name?: string, design_id?: string): Promise<{ [key: string]: ChangesetCommit }> {
        let requestUrl = new URL(this.baseUrl + "/changesets_by_design_version/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "cabinet_name": formatParam(cabinet_name, undefined),
            "design_id": formatParam(design_id, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  List Design Versions
     */
    async listDesignVersions(): Promise<DesignVersionResponse> {
        let requestUrl = new URL(this.baseUrl + "/design_versions/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Get design summaries for all design IDs
     */
    async getDesignSummaries(include_version_details?: boolean, include_bbox?: boolean, include_region?: boolean): Promise<DesignSummary[]> {
        let requestUrl = new URL(this.baseUrl + "/designs/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "include_version_details": formatParam(include_version_details, undefined),
            "include_bbox": formatParam(include_bbox, undefined),
            "include_region": formatParam(include_region, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Get summary for a given design ID
     */
    async getDesignSummary(design_id: string, include_version_details?: boolean, include_bbox?: boolean, include_region?: boolean): Promise<DesignSummary> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/designs/{design_id}/", {
                    "design_id": design_id,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "include_version_details": formatParam(include_version_details, undefined),
            "include_bbox": formatParam(include_bbox, undefined),
            "include_region": formatParam(include_region, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Check application liveness
     */
    async healthzLive(): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/healthz/live", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Check application readiness
     */
    async healthzReady(): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/healthz/ready", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Retrieve assets intersecting a list of polygons
     */
    async getAssetsByPolygonIntersections(polygons: string[], valid_at?: Date | string): Promise<AssetCollection[]> {
        let requestUrl = new URL(this.baseUrl + "/intersecting-assets/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "POST", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Content-Type", "application/json");
        fetchOptions["body"] = JSON.stringify(polygons);
        requestUrl.search = objectToUrlSearchParams({
            "valid_at": formatParam(valid_at, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Returns closures and pots that have a is_built or parent closure that was updated after the given timestamp
     */
    async getUpdatedIsBuiltStatuses(timestamp: string): Promise<BuildUpdatesSinceTimestamp> {
        let requestUrl = new URL(this.baseUrl + "/is-built-status-since/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "timestamp": formatParam(timestamp, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Update the is_built status of multiple closures or pots at a given time.
     */
    async putIsBuiltStatus(statuses?: IsBuiltStatusUpdate[]): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/is-built-status/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "PUT", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Content-Type", "application/json");
        fetchOptions["body"] = JSON.stringify(statuses);
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  List all build closures for given cabinet area
     */
    async getBuildClosures(cabinet_name: string): Promise<BuildClosureResponse[]> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/is-built-status/{cabinet_name}/", {
                    "cabinet_name": cabinet_name,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  List of all region names
     */
    async getRegionNames(): Promise<RegionNameResponse> {
        let requestUrl = new URL(this.baseUrl + "/region-names/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  List of all regions, with geometry
     */
    async getRegions(): Promise<RegionResponse> {
        let requestUrl = new URL(this.baseUrl + "/regions/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Create or update a region
     */
    async putRegions(region_data: Region[]): Promise<RegionResponse> {
        let requestUrl = new URL(this.baseUrl + "/regions/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "PUT", headers: this._headers}, this.globalFetchOptions
        );

        fetchOptions.headers.append("Content-Type", "application/json");
        fetchOptions["body"] = JSON.stringify(region_data);
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Delete a region
     */
    async deleteRegion(name: string): Promise<Response> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/regions/{name}/", {
                    "name": name,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "DELETE", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Resolve any conflicts that can be done automatically
     */
    async resolveBranchConflicts(id: number): Promise<BranchConflictResolutionResponse> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/resolve-branch-conflicts/{id}/", {
                    "id": id,
                }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, {method: "PUT", headers: this._headers}, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Get assets in closure with cabinet type (if closure is access cabinet) for a given closure_id
     */
    async getSplicingAssetsByClosureId(closure_id?: string, valid_at?: Date | string): Promise<SplicingAssetsInClosure> {
        let requestUrl = new URL(this.baseUrl + "/splicing-assets-for-closure/", window.location.origin);

        let fetchOptions = Object.assign(
            {}, {method: "GET", headers: this._headers}, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "closure_id": formatParam(closure_id, undefined),
            "valid_at": formatParam(valid_at, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

}

