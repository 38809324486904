import maplibregl, { IControl, Popup } from "maplibre-gl";
import { projectToOSGB36 } from "./utils";

export default class LocationToolControl implements IControl {
    map: maplibregl.Map;
    mapMinZoom: number;
    btn: HTMLButtonElement;
    container: HTMLDivElement;
    marker?: maplibregl.Marker;
    active: boolean;

    makePopupRow(title: string, content: string) {
        return `
        <h3 style="text-align:center">${title}</h3>
        <div style="display:flex;">
            <input style="width: 100%;text-align:center;" onclick="this.select();" value="${content}" readonly/>
        </div>`;
    }

    onClicked() {
        if (this.marker) {
            const lnglat = this.marker.getLngLat();
            const coords = projectToOSGB36([lnglat.lng, lnglat.lat]);
            const url = `https://www.google.com/maps/search/?api=1&query=${lnglat.lat},${lnglat.lng}`;
            new Popup()
                .setLngLat(lnglat)
                .setHTML(
                    `${this.makePopupRow("X Y coordinates", `${coords[0].toFixed(3)}, ${coords[1].toFixed(3)}`)}
                    ${this.makePopupRow(
                        "Latitude Longitude (decimal)",
                        `${lnglat.lat.toFixed(8)}, ${lnglat.lng.toFixed(8)}`,
                    )}
                    <a style="display:block;text-align:center;" target="_blank", href=${url}>Open in Google Maps</a>`,
                )
                .addTo(this.map);
        }
    }

    disable() {
        this.btn.classList.remove("active");
        if (this.marker) {
            this.marker.remove();
            this.marker = undefined;
            this.active = false;
        }
    }

    onAdd(map: maplibregl.Map) {
        this.map = map;
        this.active = false;

        this.btn = document.createElement("button");
        this.btn.innerHTML = `<svg style="width:24px;height:24px" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
            <g>
                <rect fill="none" height="24" width="24"/>
            </g>
            <path d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
        </svg>`;
        this.btn.type = "button";
        const tooltip = "Get Location Info";
        // @ts-ignore
        this.btn.ariaLabel = tooltip;
        this.btn.title = tooltip;
        this.btn.id = "location-tool";
        this.btn.classList.add("tooltip", "tooltip-left");
        this.btn.dataset.tooltip = tooltip;

        this.btn.onclick = () => {
            this.active = true;
            if (!this.marker) {
                this.marker = new maplibregl.Marker({ draggable: true });
                this.btn.classList.add("active");
                map.on("mousemove", e => {
                    if (this.marker) {
                        this.marker.setLngLat(e.lngLat).addTo(map);
                    }
                });
                map.on("click", () => {
                    this.onClicked();
                    this.disable();
                });
                const self = this;
                this.btn.addEventListener("blur", () => self.disable());
            } else {
                this.disable();
            }
        };

        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

        this.container.appendChild(this.btn);

        return this.container;
    }

    onRemove() {
        this.container.parentNode?.removeChild(this.container);
    }
}
