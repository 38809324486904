import { IControl, Marker } from "maplibre-gl";
import { setGigastoreUploadDialogVisibility, setGigastoreUploadMarkerPosition } from "@/store";
import { Store } from "vuex";

export default class GigastoreControl implements IControl {
    store: Store<any>;
    map: maplibregl.Map;
    mapMinZoom: number;
    btn: HTMLButtonElement;
    container: HTMLDivElement;
    marker?: Marker;

    constructor(store: Store<any>) {
        this.store = store;
    }

    onClicked() {
        if (this.marker) {
            const lngLat = this.marker.getLngLat();
            setGigastoreUploadMarkerPosition(this.store, [lngLat.lng, lngLat.lat]);
            setGigastoreUploadDialogVisibility(this.store, true);
            this.marker.remove();
            this.marker = undefined;
        }
    }

    onAdd(map: maplibregl.Map) {
        this.map = map;

        this.btn = document.createElement("button");
        this.btn.innerHTML = `<svg width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(90 12)">
                    <path d="m-85 10c-0.4-0.2-0.8-0.6-1-1v-18c0.4-0.5 0.8-1 1-1 2-0.2 5-0.08 7-0.1 0.6-3e-4 1-6e-4 2-9e-4 2 2 4 4 6 6-0.02 4-0.04 9-0.06 13-0.4 0.5-0.8 1-1 1-3 0.2-5 0.07-8 0.1-2-0.03-4 0.06-5-0.1l-0.1-0.03zm6-3v-1h3v-2h-3v-3h-2v3h-3c0.02 0.7 0.04 1 0.06 2h3c-7e-5 1-1e-4 2-2e-4 3h2c0-0.5 0-1-2e-6 -1zm7-10c-2-2-4-4-5-6v6c2-0.02 4 0.06 5-0.08z" stroke-width=".1"/>
                </g>
            </svg>
       `;
        this.btn.type = "button";
        const tooltip = "Add to Gigastore";
        // @ts-ignore
        this.btn.ariaLabel = tooltip;
        this.btn.title = tooltip;
        this.btn.id = "gigastore-upload-control";
        this.btn.classList.add("tooltip", "tooltip-left");
        this.btn.dataset.tooltip = tooltip;

        this.btn.onclick = () => {
            if (!this.marker) {
                this.marker = new Marker({ draggable: true });
                map.on("mousemove", e => {
                    if (this.marker) {
                        this.marker.setLngLat(e.lngLat).addTo(map);
                    }
                });
                map.on("click", () => {
                    this.onClicked();
                });
            } else {
                this.marker.remove();
                this.marker = undefined;
            }
        };

        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

        this.container.appendChild(this.btn);

        return this.container;
    }

    onRemove() {
        this.container.parentNode?.removeChild(this.container);
    }
}
