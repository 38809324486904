import { gigamapApiFactory } from "@/services";
import * as tsx from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { VLabel, VContainer } from "vuetify/lib";
import { CreateElement } from "vue";
import { accessTokenFromStore } from "@/utils";

@Component({
    components: {
        VLabel,
        VContainer,
    },
})
export class ClosureSplicingDiagram extends tsx.Component<{}> {
    closureId: string;
    designId: string;
    svgElement: string;
    svgLoaded: boolean = false;
    cabinetName: string | undefined;
    closureName: string | undefined;
    errorOccurred: boolean = false;
    errorMessage: string = "";

    async created() {
        try {
            this.closureId = this.$route.params.closure_id;
            this.designId = this.$route.params.designId;
            const splicingNames = JSON.parse(localStorage.getItem(this.closureId) || "{}");
            this.cabinetName = splicingNames.cabinetName || "";
            this.closureName = splicingNames.closureName || "";
            const token = accessTokenFromStore(this.$store) ?? "";
            const gigamapClient = gigamapApiFactory(token);
            if (!this.cabinetName || !this.closureName) {
                const nameResponse = await gigamapClient.getCabinetAndClosureName(this.designId, this.closureId);
                this.cabinetName = nameResponse.cabinet_name;
                this.closureName = nameResponse.closure_name;
            }
            const response = await gigamapClient.getSplicingDiagramForClosure(this.designId, this.closureId);
            this.svgElement = await response.text();
            this.svgLoaded = true;
        } catch (error) {
            console.error("An error occurred:", error);
            this.errorOccurred = true;
            let errorDetail = "";
            try {
                const errorResponse = await error.json();
                errorDetail = errorResponse.detail;
            } catch (jsonError) {
                errorDetail = error.toString();
            }
            this.errorMessage = `An error occurred while fetching SVG data. Please contact 'design.support@gigaclear.com' to correct the issue.
            Error context: ${errorDetail}`;
        }
    }

    render(h: CreateElement): JSX.Element {
        let content;
        if (this.errorOccurred) {
            content = <h2 style={{ margin: "20px" }}>Error fetching SVG data: {this.errorMessage}</h2>;
        } else if (!this.svgLoaded) {
            content = <h2 style={{ margin: "20px" }}>Loading</h2>;
        } else {
            content = [
                h(
                    "h2",
                    { style: { margin: "20px" } },
                    "Splicing Information for closure: " + this.closureName + "  in community: " + this.cabinetName,
                ),
                h("div", { domProps: { innerHTML: this.svgElement } }),
            ];
        }

        return (
            <v-container>
                <v-label>{content}</v-label>
            </v-container>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-a72a1334/closure-splicing-diagram.tsx" });