// Adapted from: https://github.com/bemky/mapbox-gl-draw-freehand-mode/blob/master/src/index.js

// @ts-ignore
import DrawLineString from "@gc/mapbox-gl-draw/src/modes/draw_line_string";
// @ts-ignore
import { geojsonTypes, cursors, types, updateActions, modes, events } from "@gc/mapbox-gl-draw/src/constants";

const FreehandMode: any = Object.assign({}, DrawLineString);

FreehandMode.onSetup = function () {
    const line = this.newFeature({
        type: geojsonTypes.FEATURE,
        properties: {},
        geometry: {
            type: geojsonTypes.LINE_STRING,
            coordinates: [],
        },
    });

    this.addFeature(line);
    this.clearSelectedFeatures();

    // Disable dragPan
    setTimeout(() => {
        if (!this.map || !this.map.dragPan) return;
        this.map.dragPan.disable();
    }, 0);

    this.updateUIClasses({ mouse: cursors.ADD });
    this.activateUIButton(types.LINE_STRING);
    this.setActionableState({ trash: true });

    return {
        line,
        currentVertexPosition: 0,
        dragMoving: false,
    };
};

FreehandMode.onDrag = FreehandMode.onTouchMove = function (state: any, e: any) {
    state.dragMoving = true;
    this.updateUIClasses({ mouse: cursors.ADD });
    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
    state.currentVertexPosition++;
    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
};

FreehandMode.onMouseUp = function (state: any, e: any) {
    if (state.dragMoving) {
        // TODO - Simplify line ?
        this.fireUpdate();
        this.changeMode(modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
    }
};

FreehandMode.fireUpdate = function () {
    this.map.fire(events.UPDATE, {
        action: updateActions.MOVE,
        features: this.getSelected().map((f: any) => f.toGeoJSON()),
    });
};

FreehandMode.onTouchEnd = function (state: any, e: any) {
    this.onMouseUp(state, e);
};

FreehandMode.onStop = function (state: any) {
    DrawLineString.onStop.call(this, state);
    // Re-enable map panning
    setTimeout(() => {
        if (!this.map || !this.map.dragPan) return;
        this.map.dragPan.enable();
    }, 0);
};

export default FreehandMode;
