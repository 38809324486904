import { Component, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import {
    VSelect,
    VTextField,
    VIcon,
    VBtn,
    VDialog,
    VStepper,
    VStepperStep,
    VStepperContent,
    VForm,
    VCard,
    VCardTitle,
} from "vuetify/lib";

import { Feature, Geometry } from "geojson";
import proj4 from "proj4";
import { CreateElement } from "vue";
import MapboxDraw from "@gc/mapbox-gl-draw";
import ExportChangesControl from "@/export-changes-control";
import { State } from "vuex-class";
import { CabinetAreaSummary } from "@/services/asset_db";
import * as store from "@/store";
import { accessTokenFromStore, isExternalUser } from "@/utils";

/**
 * Convert geom to wkt format
 */
export function toWkt(geom: Geometry): string {
    if (geom.type === "Polygon") {
        const convertedGeoms = Object.values(geom.coordinates[0]).map(coords => {
            const convertedCoords = proj4("EPSG:27700", coords);
            return `${convertedCoords[0]} ${convertedCoords[1]}`;
        });
        return `POLYGON((${convertedGeoms.join(",")}))`;
    } else if (geom.type === "Point") {
        const convertedCoords = proj4("EPSG:27700", geom.coordinates);
        return `POINT (${convertedCoords[0]} ${convertedCoords[1]})`;
    }
    throw new Error("WKT conversion only supported for Polygons & Points");
}

enum FeatureType {
    Note = "note",
    NOIPolyon = "noi_order",
    SED = "sed",
    Redline = "redline",
}

export interface SelectItem {
    text: string;
    value: string;
}

function getFeatureTypes(geometry: Geometry): SelectItem[] {
    switch (geometry.type) {
        case "Polygon":
            return [
                { value: FeatureType.SED, text: "SED" },
                { value: FeatureType.NOIPolyon, text: "NOI Polygon" },
            ];
        case "LineString":
            return [{ value: FeatureType.Redline, text: "Redline" }];
        case "Point":
            return [{ value: FeatureType.Note, text: "Note" }];
        default:
            return [];
    }
}

export interface AssetProp {
    name: string;
    type: string;
    value: string;
    label: string;
    values?: SelectItem[];
}

@Component({
    components: {
        VCard,
        VCardTitle,
        VSelect,
        VTextField,
        VIcon,
        VBtn,
        VDialog,
        VStepper,
        VStepperContent,
        VStepperStep,
        VForm,
    },
})
export default class NewFeatureDialog extends tsx.Component<{}> {
    visible: boolean;
    featureType?: string;
    step: number;
    feature?: Feature;
    mapDraw?: MapboxDraw;
    exportChangesControl?: ExportChangesControl;
    featureProperties: { [key: string]: any };
    assetProps?: AssetProp[];
    communities: string[];

    @State((s: store.State) => s.cabAreas) cabAreas: CabinetAreaSummary[];

    data() {
        return {
            visible: false,
            assetType: undefined,
            step: 1,
            feature: undefined,
            mapDraw: undefined,
            exportChangesControl: undefined,
            featureProperties: {},
            assetProps: undefined,
            featureType: undefined,
            communities: [],
        };
    }

    async mounted() {
        proj4.defs(
            "EPSG:27700",
            "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717" +
                "+x_0=400000 +y_0=-100000 +ellps=airy " +
                "+towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 " +
                "+units=m +no_defs",
        );
    }

    getAssetDefinitions(featureType: string, cabinetAreas: SelectItem[]): AssetProp[] {
        // Throws a type error in older typescript versions
        // @ts-ignore
        const newUuid = crypto.randomUUID();
        const assetDefinitions: { [key: string]: AssetProp[] } = {
            redline: [
                {
                    name: "text",
                    type: "string",
                    label: "Text",
                    value: this.feature?.properties?.text ?? "",
                },
            ],
            note: [
                {
                    name: "id",
                    value: this.feature?.properties?.id ?? newUuid,
                    type: "read",
                    label: "ID",
                },
                {
                    name: "text",
                    type: "string",
                    label: "Text",
                    value: this.feature?.properties?.text ?? "",
                },
                {
                    name: "cabinet_area",
                    type: "select",
                    values: cabinetAreas,
                    value: this.feature?.properties?.cabinet_area,
                    label: "Community",
                },
            ],
            noi_order: [
                {
                    name: "cabinet_area",
                    type: "select",
                    values: cabinetAreas,
                    value: this.feature?.properties?.cabinet_area,
                    label: "Community",
                },
                {
                    name: "noi_number",
                    type: "string",
                    label: "NOI Number",
                    value: this.feature?.properties?.noi_number ?? "",
                },
                {
                    name: "id",
                    value: this.feature?.properties?.id ?? newUuid,
                    type: "read",
                    label: "ID",
                },
            ],
            sed: [
                {
                    name: "text",
                    type: "string",
                    label: "Text",
                    value: this.feature?.properties?.text ?? "",
                },
                {
                    name: "cabinet_area",
                    type: "select",
                    values: cabinetAreas,
                    label: "Community",
                    value: this.feature?.properties?.cabinet_area,
                },
                {
                    name: "id",
                    value: this.feature?.properties?.id ?? newUuid,
                    type: "read",
                    label: "ID",
                },
            ],
        };
        return assetDefinitions[featureType];
    }

    get newFeatureForm() {
        if (!this.feature) {
            return;
        }
        const featureTypes = getFeatureTypes(this.feature.geometry);
        const newFeatureDetails = (
            <v-form
                onSubmit={(event: Event) => {
                    event.preventDefault();
                    this.createNewFeature();
                }}
            >
                {this.assetProps &&
                    this.assetProps.map(field => {
                        if (field.type === "string") {
                            return (
                                <v-text-field
                                    v-model={this.featureProperties[field.name]}
                                    label={field.label}
                                ></v-text-field>
                            );
                        } else if (field.type === "select") {
                            return (
                                <v-select
                                    v-model={this.featureProperties[field.name]}
                                    label={field.label}
                                    items={field.values}
                                ></v-select>
                            );
                        } else if (field.type === "read") {
                            return <v-text-field value={field.value} label={field.label} disabled></v-text-field>;
                        }
                    })}
                <v-btn onClick={() => (this.step = 1)} class="mr-2">
                    Back
                </v-btn>
                <v-btn onClick={() => this.createNewFeature()}>Save</v-btn>
            </v-form>
        );
        return (
            <v-stepper v-model={this.step}>
                <v-stepper-step complete={this.step > 1} step="1">
                    Feature Type
                </v-stepper-step>
                <v-stepper-content step="1" style="padding-top: 0px">
                    <v-select v-model={this.featureType} items={featureTypes} label="Feature Type"></v-select>
                    <v-btn enabled={this.featureType !== undefined} onClick={() => (this.step = 2)}>
                        Continue
                    </v-btn>
                </v-stepper-content>
                <v-stepper-step step="2">Details</v-stepper-step>
                <v-stepper-content step="2" style="padding-top: 0px">
                    {newFeatureDetails}
                </v-stepper-content>
            </v-stepper>
        );
    }

    @Watch("featureType")
    onFeatureTypeChange() {
        if (this.featureType) {
            const cabIdsByName = Object.fromEntries(this.cabAreas.map(cab => [cab.name, cab.latest_cabinet_id]));
            const cabinetAreas = this.communities.map(community => {
                return { text: community, value: cabIdsByName[community] };
            });
            this.assetProps = this.getAssetDefinitions(this.featureType, cabinetAreas);
            this.featureProperties = Object.fromEntries(
                this.assetProps.map(field => {
                    if (field.type === "select" && field.values) {
                        return [field.name, field.values[0].value];
                    } else {
                        return [field.name, field.value];
                    }
                }),
            );
        } else {
            this.assetProps = undefined;
        }
    }

    open(
        feature: Feature,
        mapDraw: MapboxDraw,
        exportChangesControl: ExportChangesControl,
        communities: string[],
        step = 1,
    ) {
        this.feature = feature;
        this.visible = true;
        this.mapDraw = mapDraw;
        this.featureType = undefined;
        this.step = step;
        this.exportChangesControl = exportChangesControl;
        this.featureProperties = {};
        if (this.feature.properties && this.feature.properties.type) {
            this.featureType = this.feature.properties.type;
        } else {
            this.featureType = getFeatureTypes(this.feature.geometry)[0].value;
        }
        this.communities = communities;
    }

    createNewFeature() {
        this.visible = false;
        if (this.feature && this.mapDraw && typeof this.feature.id === "string") {
            for (const [key, value] of Object.entries(this.featureProperties)) {
                this.mapDraw.setFeatureProperty(this.feature.id, key, value);
            }
            this.mapDraw.setFeatureProperty(this.feature.id, "type", this.featureType);
            this.mapDraw.changeMode("simple_select");
            this.mapDraw = undefined;
            if (this.exportChangesControl) {
                const token = accessTokenFromStore(this.$store) ?? "";
                this.exportChangesControl.setEnabled(true, isExternalUser(token));
            }
            this.featureType = undefined;
        }
    }

    @Watch("visible")
    onVisibleChange() {
        if (!this.visible && this.mapDraw && typeof this.feature?.id === "string") {
            if (Object.keys(this.feature.properties ?? {}).length === 0) {
                this.mapDraw.delete(this.feature?.id);
            }
            const features = this.mapDraw.getAll();
            if (features.features.length === 0 && this.exportChangesControl) {
                this.exportChangesControl.setEnabled(false, false);
            }
            this.featureType = undefined;
            this.mapDraw.changeMode("simple_select");
        }
    }

    render(h: CreateElement): JSX.Element | undefined {
        return (
            <v-dialog v-model={this.visible} max-width="500px">
                <v-card>
                    <v-card-title>Add/Edit Feature</v-card-title>
                    {this.newFeatureForm}
                </v-card>
            </v-dialog>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-8662c5ca/asset-data-popup.tsx" });