import { CreateElement } from "vue";
import { Component } from "vue-property-decorator";
import { State } from "vuex-class";
import * as tsx from "vue-tsx-support";
import {
    VCheckbox,
    VCard,
    VCardTitle,
    VBtn,
    VIcon,
    VSpacer,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
} from "vuetify/lib";

import * as store from "@/store";

@Component({
    components: {
        VCheckbox,
        VCard,
        VCardTitle,
        VBtn,
        VIcon,
        VSpacer,
        VExpansionPanel,
        VExpansionPanels,
        VExpansionPanelContent,
        VExpansionPanelHeader,
    },
})
export default class MapLayersControl extends tsx.Component<{}> {
    @State((s: store.State) => s.layers) layers: store.Layers;
    @State((s: store.State) => s.layersControlVisible) visible: boolean;

    checkboxes(): [JSX.Element[], JSX.Element[]] {
        const ungrouped: JSX.Element[] = [];
        const grouped: JSX.Element[] = [];
        const elementsByGroup: { [key: string]: JSX.Element[] } = {};
        for (const [layerName, layer] of Object.entries(this.layers)) {
            const element = (
                <v-checkbox
                    class="ma-0 mb-2 pa-0"
                    hide-details
                    label={layer.label}
                    key={layerName}
                    input-value={layer.visible}
                    onChange={(state: boolean) => {
                        const update = { layer: layerName, visible: state === true };
                        store.setLayerVisibility(this.$store, update);
                    }}
                />
            );
            const key = layer.group || "";
            key in elementsByGroup ? elementsByGroup[key].push(element) : (elementsByGroup[key] = [element]);
        }
        Object.entries(elementsByGroup).map(([groupName, checkboxes]) => {
            if (groupName !== "") {
                grouped.push(
                    <v-expansion-panel>
                        <v-expansion-panel-header class="ma-0 mb-1 pa-0">{groupName}</v-expansion-panel-header>
                        <v-expansion-panel-content>{checkboxes}</v-expansion-panel-content>
                    </v-expansion-panel>,
                );
            } else {
                ungrouped.push(...checkboxes);
            }
        });
        return [ungrouped, grouped];
    }

    close() {
        store.setLayerControlVisibility(this.$store, false);
    }

    render(h: CreateElement): JSX.Element | undefined {
        if (this.visible) {
            const [ungrouped, grouped] = this.checkboxes();
            return (
                <v-card class="sidepanel layers-control">
                    <v-card-title class="ma-0 mb-1 pa-0">
                        Layers
                        <v-spacer></v-spacer>
                        <v-btn icon onClick={this.close}>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    {ungrouped}
                    <v-expansion-panels accordion flat focusable>
                        {grouped}
                    </v-expansion-panels>
                </v-card>
            );
        }
        return undefined;
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-5ae65cb8/map-layers-control.tsx" });