import { CreateElement } from "vue";
import { Component, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { State } from "vuex-class";
import { VIcon, VTextField, VTooltip } from "vuetify/lib";
import { debounce } from "underscore";

import * as store from "@/store";
import { messages } from "@/services";

// No-op function to catch NavigationDuplicated errors
export function noop() {
    /* tslint: ignore */
}

@Component({
    components: {
        "v-text-field": VTextField,
        "v-icon": VIcon,
        "v-tooltip": VTooltip,
    },
})
export default class SearchResults extends tsx.Component<{}> {
    @State((s: store.State) => s.layers) layers: store.Layers;

    query?: string;
    nonce: number = 0;

    data() {
        return {
            query: undefined,
        };
    }

    @Watch("layers")
    onLayersLoaded() {
        if (Object.keys(this.layers).length > 0) {
            const query = this.$route.query.q;
            if (typeof query === "string") {
                this.query = query;
                messages.$emit("search", query, this.increment());
            }
        }
    }

    render(h: CreateElement): JSX.Element {
        return (
            <v-text-field
                id="search-input"
                class="d-inline-flex"
                placeholder="Search..."
                onInput={debounce(this.onSearchInput, 500)}
                hide-details
                dark
                append-icon="mdi-magnify"
                value={this.query}
            >
                <v-tooltip
                    bottom
                    open-delay={400}
                    slot="append-outer"
                    scopedSlots={{
                        activator: ({ on }: any) => {
                            return (
                                <v-icon onClick={() => this.query && this.onSearchInput(this.query)} {...{ on }}>
                                    mdi-refresh
                                </v-icon>
                            );
                        },
                    }}
                >
                    Search this area
                </v-tooltip>
            </v-text-field>
        );
    }

    increment(): number {
        this.nonce += 1;
        return this.nonce;
    }

    onSearchInput(text: string) {
        this.query = text.trim();
        messages.$emit("search", this.query, this.increment());
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-5c2d163a/search-input.tsx" });