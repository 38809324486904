import Vue, { CreateElement } from "vue";
import { Component, Watch } from "vue-property-decorator";
import {
    VApp,
    VSpacer,
    VToolbar,
    VToolbarTitle,
    VMain,
    VFooter,
    VSnackbar,
    VAppBar,
    VChip,
    VAvatar,
    VIcon,
    VBtn,
    VMenu,
    VList,
    VListItem,
} from "vuetify/lib";

import PageContainer from "@/components/page-container";
import AppBar from "@/components/app-bar";
import { APP_NAME } from "@/appconfig";
import { setUsername, setAccessToken } from "@/store";
import { messages, getAuthToken, TokenPayload } from "@/services";
import { UserIdPayload, getAccessToken, logout, clearLocalTokens, getUserData } from "@/oauth";
import { getAuthVersion } from "@/auth-helpers";
import { isMobileDisplay } from "./utils";

enum AvatarState {
    WAITING,
    OK,
    ERROR,
}

@Component({
    components: {
        PageContainer,
        VAppBar,
        VToolbar,
        VSnackbar,
        VSpacer,
        VToolbarTitle,
        VFooter,
        VApp,
        VChip,
        VAvatar,
        VIcon,
        VBtn,
        VMain,
        VMenu,
        VList,
        VListItem,
    },
})
export default class App extends Vue {
    username: string;
    showError: boolean;
    errorMessage: string;
    avatarState: AvatarState = AvatarState.WAITING;
    loading: boolean;
    forbiddenError: boolean;
    notFoundError: boolean;
    notFoundMessage?: string;
    authServiceUrl?: string;
    displayMobile: boolean;
    tokenTimer: any;

    data() {
        return {
            username: undefined,
            showError: false,
            noAvatar: false,
            errorMessage: "",
            loading: false,
            notFoundError: false,
            forbiddenError: false,
            notFoundMessage: undefined,
            authServiceUrl: undefined,
            displayMobile: isMobileDisplay(),
        };
    }

    // As the transformRequest() in map.tsx mapbox options doesn't allow async fuctions,
    //  we are setting the token in the state object and using a timer here to refresh it.
    setTokenRefresh() {
        if (!this.tokenTimer) {
            this.tokenTimer = setTimeout(async () => {
                const token = await getAccessToken();
                if (token) {
                    setAccessToken(this.$store, token);
                }
            }, 300000);
        }
    }

    // 1. When the app starts and it checks for an existing login
    // 2. After a new login the "login" event is emitted which is handled here
    async onLoggedIn(userData: UserIdPayload | TokenPayload) {
        this.username = userData && userData.uid ? userData.uid : "";
        setUsername(this.$store, userData.uid);

        if (this.$apm) {
            this.$apm.setUserContext({ username: this.username });

            const rolesData = userData && userData.roles ? userData.roles : [];
            if (rolesData.length > 0) {
                const roles = Array.from(rolesData).sort().join(",");
                this.$apm.addLabels({ roles });
            }
        }

        let token;
        try {
            const authVersion = await getAuthVersion();
            if (authVersion === "AUTH_V2") {
                token = await getAccessToken();
                this.setTokenRefresh();
            } else if (authVersion === "AUTH_V1") {
                const [jwt] = getAuthToken();
                token = jwt;
            }
        } catch (error) {
            if (!(error instanceof TypeError)) {
                throw error;
            }
            const [jwt] = getAuthToken();
            token = jwt;
        }
        if (token) {
            setAccessToken(this.$store, token);
        }
    }

    async logout() {
        if (this.$store.state.authVersion === "AUTH_V2") {
            setAccessToken(this.$store, "");
            setUsername(this.$store, "");
            this.username = "";
            if (this.tokenTimer) {
                clearTimeout(this.tokenTimer);
            }
            logout().then(() => {
                clearLocalTokens();
                this.$router.push({ path: "/login" });
            });
        }
        // NOTE - gcauth.client.aiohttp doesn't have a "/logout" route yet (for AUTH_V1)
    }

    get appName() {
        return APP_NAME;
    }

    @Watch("$route")
    resetErrors() {
        this.notFoundMessage = undefined;
        this.notFoundError = false;
        this.forbiddenError = false;
        this.showError = false;
    }

    async mounted() {
        messages.$on("login", this.onLoggedIn);
        messages.$on("apiError", (message: string) => {
            this.errorMessage = message;
            this.showError = true;
        });
        messages.$on("loggedOut", () => {
            this.username = "";
        });
        messages.$on("notFoundError", () => {
            this.$router.push("/not-found");
        });
        messages.$on("unauthorized", () => {
            if (this.$route.path !== "/login") {
                this.username = "";
                this.$router.push({ path: "/login", query: { redirect: this.$route.fullPath } });
            }
        });
        messages.$on("forbidden", () => {
            this.forbiddenError = true;
        });

        let userData = null;
        try {
            const authVersion = await getAuthVersion();
            // TODO: This needs refactoring - authentication backend should be completely abstracted
            if (authVersion === "AUTH_V1") {
                const [, payload] = getAuthToken();
                userData = payload;
            } else if (authVersion === "AUTH_V2") {
                userData = getUserData();
            }
        } catch (error) {
            if (!(error instanceof TypeError)) {
                throw error;
            }
            const [, payload] = getAuthToken();
            userData = payload;
        }
        if (userData) {
            this.onLoggedIn(userData);
        }
    }

    get user() {
        const initials = this.username
            .split(".")
            .map(word => word[0])
            .join("")
            .toUpperCase();
        return initials;
    }

    render(h: CreateElement) {
        return (
            <v-app>
                <v-app-bar fixed app extension-height="3" height="62" extended class="primary">
                    {!this.displayMobile && [
                        <img src="/Gigaclear_MasterLogo_white.svg" style="width: 10%; min-width: 120px" />,
                        <v-toolbar-title class="ml-2">{this.appName}</v-toolbar-title>,
                    ]}
                    <AppBar />
                    <v-spacer></v-spacer>
                    {this.username && [
                        <v-menu
                            offset-y
                            scopedSlots={{
                                activator: ({ on }: any) => {
                                    if (this.displayMobile) {
                                        return (
                                            <v-btn icon color="white" onClick={on.click}>
                                                <v-avatar>
                                                    <v-icon>mdi-account-circle</v-icon>
                                                </v-avatar>
                                            </v-btn>
                                        );
                                    } else {
                                        return (
                                            <v-chip id="username" slot="activator" class="ml-1" onClick={on.click}>
                                                <v-avatar color="secondary" left>
                                                    <v-icon>mdi-account-circle</v-icon>
                                                </v-avatar>
                                                {this.user}
                                            </v-chip>
                                        );
                                    }
                                },
                            }}
                        >
                            <v-list>
                                <v-list-item
                                    text
                                    color="white"
                                    onClick={() => {
                                        this.logout();
                                    }}
                                >
                                    Logout
                                </v-list-item>
                            </v-list>
                        </v-menu>,
                    ]}
                </v-app-bar>
                <v-main>
                    <transition name="router" mode="out-in">
                        <PageContainer
                            forbiddenError={this.forbiddenError}
                            notFoundError={this.notFoundError}
                            notFoundMessage={this.notFoundMessage}
                        >
                            <router-view class="view router-view"></router-view>
                        </PageContainer>
                    </transition>
                    <v-snackbar bottom v-model={this.showError} timeout={60000}>
                        <span>{this.errorMessage}</span>
                        <v-btn text color="orange" onClick={() => (this.showError = false)}>
                            Close
                        </v-btn>
                    </v-snackbar>
                </v-main>
            </v-app>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-55ac039d/app.tsx" });