import { CreateElement } from "vue";
import { Component } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { State } from "vuex-class";
import { VBtn, VCard, VCardTitle, VIcon, VList, VListItem, VListItemContent, VListItemTitle } from "vuetify/lib";

import { FeatureInfo } from "@/services/gigamap";
import { QueryResults } from "@/views/map";
import * as store from "@/store";

export function layerDescriptions(layers: store.Layers): { [key: string]: { [key: string]: string } } {
    const descriptions: { [key: string]: { [key: string]: string } } = {};
    for (const layer of Object.values(layers)) {
        descriptions[layer.label] = {};
        for (const item of layer.properties) {
            descriptions[layer.label][item.key] = item.description;
        }
    }
    return descriptions;
}

interface SearchResultsEvents {
    onPanTo: { geometry: number[]; bounds: number[] };
}

@Component({
    components: {
        "v-btn": VBtn,
        "v-card": VCard,
        "v-card-title": VCardTitle,
        "v-icon": VIcon,
        "v-list": VList,
        "v-list-item": VListItem,
        "v-list-item-title": VListItemTitle,
        "v-list-item-content": VListItemContent,
    },
})
export default class SearchResults extends tsx.Component<{}, SearchResultsEvents> {
    @State((s: store.State) => s.layers) layers: store.Layers;
    @State((s: store.State) => s.searchResults?.results) results?: QueryResults;
    @State((s: store.State) => s.searchResultsVisible) visible: boolean;

    render(h: CreateElement): JSX.Element | undefined {
        if (this.visible) {
            return (
                <v-card class="sidepanel search-results-control">
                    {this.closeBtn()}
                    <v-card-title class="ma-0 mb-1 pa-0">Results</v-card-title>
                    {this.results !== undefined && (
                        <v-list dense class="pa-2">
                            {this.renderQueryResults(this.results)}
                        </v-list>
                    )}
                </v-card>
            );
        }
        return undefined;
    }

    closeBtn(): JSX.Element {
        return (
            <v-btn icon style="float: right" onClick={() => store.setSearchResultsVisibility(this.$store, false)}>
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        );
    }

    renderQueryResults(results: QueryResults): JSX.Element[] {
        const items = [];
        const descriptions = layerDescriptions(this.layers);
        descriptions.Addresses = { address: "Address" };
        if (Object.keys(results).length === 0) {
            return [<p>No results</p>];
        }
        for (const layerName in results) {
            if (results.hasOwnProperty(layerName)) {
                const features = results[layerName];
                items.push(<v-list-item-title class="search-results-group">{layerName}</v-list-item-title>);
                for (const feature of features) {
                    items.push(this.renderFeatureInfo(feature, descriptions[layerName], layerName));
                }
            }
        }
        return items;
    }

    renderFeatureInfo(feature: FeatureInfo, descriptions: { [key: string]: string }, layerName: string): JSX.Element {
        return (
            <v-list-item class="search-results-item pl-0 pr-0" onClick={() => this.onResultClicked(feature)}>
                <v-list-item-content>
                    {feature.properties
                        .filter(prop => !!prop.value)
                        .map(prop => {
                            const description = descriptions[prop.key];
                            if (layerName === "Communities" && description === "Short Name") {
                                return (
                                    <div>
                                        <b>{description}</b>: {prop.value.split(",").join(", ")}
                                    </div>
                                );
                            } else if (layerName === "Communities" && description === "Friendly Name") {
                                return (
                                    <div>
                                        <b>{description}</b>: {prop.value.split(",").join(" & ")}
                                    </div>
                                );
                            } else {
                                return (
                                    <div>
                                        <b>{description}</b>: {prop.value}
                                    </div>
                                );
                            }
                        })}
                </v-list-item-content>
            </v-list-item>
        );
    }

    onResultClicked(feature: FeatureInfo): void {
        if (!feature.bounds) {
            return;
        }
        this.$emit("panTo", feature);
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-3796f726/search-results.tsx" });